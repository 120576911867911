import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useState } from 'react';

type Props = {
    newObject: any;
    postQuery: (obj: any) => Promise<Response>;
};

function AdminSubmitButton(props: Props) {
    const [result, setResult] = useState('NONE');

    const handleSubmit = async (e: any) => {
        setResult('LOADING');
        const response = await props.postQuery(props.newObject);
        setResult(response.ok ? 'SUCCESS' : 'FAILURE');
    };

    return (
        <Button
            isLoading={result === 'LOADING'}
            loadingText="Loading"
            colorScheme={result === 'FAILURE' ? 'orange' : 'teal'}
            spinnerPlacement="start"
            onClick={handleSubmit}
        >
            Submit
            {result === 'SUCCESS' && <CheckCircleIcon marginLeft={2} />}
            {result === 'FAILURE' && <WarningTwoIcon marginLeft={2} />}
        </Button>
    );
}

export default AdminSubmitButton;
