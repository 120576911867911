import { Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import AdminSubmitButton from './AdminSubmitButton';
import { postProcessingMethod } from '../queries';

function AdminProcessingMethods() {
    const handleFocus = (e: any) => e.target.select();

    const [id, setId] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [nameFr, setNameFr] = useState('');

    const handleId = (e: any) => {
        setId(e.target.value);
    };

    const handleNameEn = (e: any) => {
        setNameEn(e.target.value);
    };

    const handleNameFr = (e: any) => {
        setNameFr(e.target.value);
    };

    return (
        <Stack spacing={8}>
            <Stack spacing={2}>
                <Text>ID</Text>
                <Input placeholder="WSH" value={id} onChange={handleId} onFocus={handleFocus} />
            </Stack>
            <Stack spacing={2}>
                <Text>Name (en)</Text>
                <Input
                    placeholder="Washed"
                    value={nameEn}
                    onChange={handleNameEn}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={2}>
                <Text>Name (fr)</Text>
                <Input
                    placeholder="Lavé"
                    value={nameFr}
                    onChange={handleNameFr}
                    onFocus={handleFocus}
                />
            </Stack>
            <AdminSubmitButton
                newObject={{
                    id,
                    nameEn,
                    nameFr,
                }}
                postQuery={postProcessingMethod}
            />
        </Stack>
    );
}

export default AdminProcessingMethods;
