import { AddIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Card,
    Center,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { CiCoffeeBean } from 'react-icons/ci';
import { useQueryClient } from 'react-query';
import { addToFavorites } from '../queries';
import SearchBar from '../Search/SearchBar';
import { cardStyles } from '../styles/Card';

type Props = {
    index: number;
    withAdd: boolean;
};

function DefaultCard(props: Props) {
    const initialFocusRef = useRef(null);
    const queryClient = useQueryClient();

    const handleAdd = async (coffeeId: number) => {
        await addToFavorites(props.index, coffeeId);
        queryClient.invalidateQueries('favorites');
    };

    return (
        <Box textAlign={'center'}>
            <Card
                height={'185px'}
                width={'150px'}
                {...cardStyles()}
                p="10px"
                mb={'-15px'}
                justifyContent={'center'}
            >
                <Center>
                    <Icon as={CiCoffeeBean} boxSize={12} />
                </Center>
            </Card>
            {props.withAdd && (
                <Popover initialFocusRef={initialFocusRef}>
                    <PopoverTrigger>
                        <Button
                            size="xs"
                            height={6}
                            width={6}
                            borderRadius={50}
                            bgColor={'blue.700'}
                            variant="outline"
                            _hover={{ bgColor: 'blue.500' }}
                        >
                            <AddIcon />
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverBody>
                                <SearchBar focusRef={initialFocusRef} handleFn={handleAdd} />
                            </PopoverBody>
                        </PopoverContent>
                    </Portal>
                </Popover>
            )}
        </Box>
    );
}

export default DefaultCard;
