import { Box } from '@chakra-ui/react';
import CoffeeList from './CoffeeList';

function CoffeesPage() {
    return (
        <Box paddingTop="2">
            <CoffeeList />
        </Box>
    );
}

export default CoffeesPage;
