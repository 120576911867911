import { Highlight } from '@chakra-ui/react';

type Props = {
    name: string;
};

function Username(props: Props) {
    return (
        <Highlight
            query={props.name}
            styles={{ px: '2', py: '1', rounded: 'full', bg: 'teal.500' }}
        >
            {props.name}
        </Highlight>
    );
}

export default Username;
