import {
    Card,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getCoffeeById } from '../queries';
import LogoSpinner from '../LogoSpinner';
import { AiOutlineFire } from 'react-icons/ai';
import { CiCoffeeBean, CiLocationArrow1, CiMountain1 } from 'react-icons/ci';
import { BiSun } from 'react-icons/bi';
import { RxMixerVertical } from 'react-icons/rx';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import CoffeeLikeButton from './CoffeeLikeButton';
import CoffeeLogButton from './CoffeeLogButton';
import CoffeeDrinklistButton from './CoffeeDrinklistButton';
import { Coffee, COFFEES_IMG_URL } from '../types';
import { Link as ReactRouterLink } from 'react-router-dom';
import i18n from '../Languages/Translations';

function CoffeePage() {
    const { id } = useParams();

    const coffeeQuery = id ? getCoffeeById(id) : undefined;

    if (!coffeeQuery || coffeeQuery?.isFetching) {
        return (
            <Center>
                <LogoSpinner />
            </Center>
        );
    }

    const coffee: Coffee = coffeeQuery.coffee;

    const altitudeText =
        coffee.altitudeMin === coffee.altitudeMax
            ? `${coffee.altitudeMin} masl`
            : `${coffee.altitudeMin}-${coffee.altitudeMax} masl`;

    return (
        <Center py={4}>
            <Card
                p={4}
                width="100%"
                bgColor="transparent"
                borderWidth="2px"
                borderColor="#303030"
                borderRadius="18px"
                justifyContent="center"
            >
                <Stack align="center" gap={6}>
                    <Center>
                        <Image
                            src={`${COFFEES_IMG_URL}/${coffee.id}.png`}
                            fallbackSrc={coffee.imageUrl}
                            fallbackStrategy="onError"
                            alt={'Logo of ' + coffee.name}
                            borderRadius="lg"
                            width="300px"
                            objectFit="cover"
                        />
                    </Center>
                    <Stack textAlign={'left'}>
                        <Link
                            as={ReactRouterLink}
                            to={`/roasters/${coffee.roasterId}`}
                            style={{ textDecoration: 'none' }}
                        >
                            <Flex align={'center'}>
                                <Icon as={AiOutlineFire} mr={2} />
                                <Heading size="sm" noOfLines={1}>
                                    {coffee.roasterName}
                                </Heading>
                            </Flex>
                        </Link>
                        <Flex align={'center'}>
                            <Icon as={CiCoffeeBean} mr={2} />
                            <Heading size="sm" noOfLines={1}>
                                {coffee.name}
                            </Heading>
                        </Flex>
                        {coffee.isSingleOrigin ? (
                            <>
                                <Flex align={'center'}>
                                    <Icon as={CiLocationArrow1} mr={2} />
                                    <Text fontSize="sm" noOfLines={1}>
                                        {i18n.defaultLocale === 'en'
                                            ? coffee.countryEn
                                            : coffee.countryFr}
                                    </Text>
                                </Flex>
                                {coffee.processingMethodEn !== 'Unknown' ? (
                                    <Flex align={'center'}>
                                        <Icon as={BiSun} mr={2} />
                                        <Text noOfLines={1} fontSize="sm">
                                            {i18n.defaultLocale === 'en'
                                                ? coffee.processingMethodEn
                                                : coffee.processingMethodFr}
                                            {coffee.isDecaf ? (
                                                <>
                                                    {' '}
                                                    - <i>{i18n.t('decaffeinated')}</i>
                                                </>
                                            ) : null}
                                        </Text>
                                    </Flex>
                                ) : null}
                                {altitudeText !== '0 masl' ? (
                                    <Flex align={'center'}>
                                        <Icon as={CiMountain1} mr={2} />
                                        <Text fontSize="sm">{altitudeText}</Text>
                                    </Flex>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Flex align={'center'}>
                                    <Icon as={RxMixerVertical} mr={2} />
                                    <Text noOfLines={1} fontSize="sm">
                                        {i18n.t('blend')}
                                        {coffee.isDecaf ? (
                                            <>
                                                {' '}
                                                - <i>{i18n.t('decaffeinated')}</i>
                                            </>
                                        ) : null}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Stack>
                    <HStack gap={4} color={'gray.300'}>
                        <CoffeeLogButton coffee={coffee} />
                        <CoffeeLikeButton coffee={coffee} />
                        <CoffeeDrinklistButton coffee={coffee} />
                        <Center height="50px">
                            <Divider orientation="vertical" />
                        </Center>
                        <Link href={coffee.siteUrl} isExternal>
                            <VStack cursor="pointer">
                                <ExternalLinkIcon boxSize={6} />
                                <Text fontSize="sm" userSelect="none">
                                    {i18n.t('website')}
                                </Text>
                            </VStack>
                        </Link>
                    </HStack>
                </Stack>
            </Card>
        </Center>
    );
}

export default CoffeePage;
