import { Box, Heading, Icon, SimpleGrid, Wrap, WrapItem } from '@chakra-ui/react';
import { FaDiceFour } from 'react-icons/fa';
import DefaultCard from './DefaultCard';
import CoffeeCardById from './CoffeeCardById';
import i18n from '../Languages/Translations';

type Props = {
    coffeeIds: number[];
};

function FavoriteCoffees(props: Props) {
    return (
        <>
            <Wrap align="center">
                <WrapItem>
                    <Icon as={FaDiceFour} w={5} h={5} color={'green.400'} />
                </WrapItem>
                <WrapItem>
                    <Heading size="md">{i18n.t('favoriteCoffees')}</Heading>
                </WrapItem>
            </Wrap>
            <SimpleGrid columns={2} spacing="30px">
                {props.coffeeIds.map((id, index) => (
                    <Box key={`fav_${index}`}>
                        {id ? (
                            <CoffeeCardById coffeeId={id} size={'lg'} />
                        ) : (
                            <DefaultCard index={index} withAdd={false} />
                        )}
                    </Box>
                ))}
            </SimpleGrid>
        </>
    );
}

export default FavoriteCoffees;
