import { Icon, Text, VStack } from '@chakra-ui/react';
import { Coffee } from '../types';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Users/UserContext';
import { useQueryClient } from 'react-query';
import { getIsInDrinklist, putInDrinklist, removeFromDrinklist } from '../queries';
import { BiListCheck, BiListPlus } from 'react-icons/bi';
import i18n from '../Languages/Translations';

type Props = {
    coffee: Coffee;
};

function CoffeeDrinklistButton(props: Props) {
    const queryClient = useQueryClient();

    const { user } = useContext(UserContext);
    const userFound = !!user?.username;

    const inDrinklistQuery = getIsInDrinklist(props.coffee.id);

    const [inDrinklist, setInDrinklist] = useState(false);
    const [isHovering, setHover] = useState(false);

    useEffect(() => {
        setInDrinklist(inDrinklistQuery.isCoffeeInDrinklist);
    }, [inDrinklistQuery.isCoffeeInDrinklist]);

    if (!inDrinklistQuery.isFetched) {
        return null;
    }

    const handleDrinklist = async (e: any) => {
        if (inDrinklist) {
            await removeFromDrinklist(props.coffee.id);
            setInDrinklist(false);
        } else {
            await putInDrinklist(props.coffee.id);
            setInDrinklist(true);
        }
        queryClient.invalidateQueries('drinklist');
    };

    return (
        <VStack
            width={'75px'}
            pointerEvents={userFound ? 'auto' : 'none'}
            opacity={userFound ? '1' : '0.5'}
            cursor="pointer"
            _hover={{ color: 'white' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleDrinklist}
        >
            {inDrinklist ? (
                <>
                    <Icon boxSize={6} as={BiListCheck} color={'blue.400'} />
                    <Text fontSize="sm" userSelect="none">
                        {isHovering ? <>{i18n.t('remove')}</> : <>{i18n.t('drinklist')}</>}
                    </Text>
                </>
            ) : (
                <>
                    <Icon boxSize={6} as={BiListPlus} />
                    <Text fontSize="sm" userSelect="none" noOfLines={1}>
                        {i18n.t('drinklist')}
                    </Text>
                </>
            )}
        </VStack>
    );
}

export default CoffeeDrinklistButton;
