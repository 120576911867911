import { Center, SimpleGrid, Text } from '@chakra-ui/react';
import CoffeeCardLight from '../Coffees/CoffeeCardLight';
import i18n from '../Languages/Translations';
import { getDrinklist } from '../queries';

function DrinklistEdit() {
    const drinklistQuery = getDrinklist();

    if (!drinklistQuery.isFetched) {
        return null;
    }

    if (drinklistQuery.coffees.length === 0) {
        return (
            <Text fontSize="sm" fontStyle="italic">
                {i18n.t('noCoffeesInDrinklist')}
            </Text>
        );
    }

    return (
        <Center>
            <SimpleGrid columns={[3, null, 6]} spacing="10px">
                {drinklistQuery.coffees.map((coffee) => (
                    <CoffeeCardLight key={'coffee_' + coffee.id} coffee={coffee} size="md" />
                ))}
            </SimpleGrid>
        </Center>
    );
}

export default DrinklistEdit;
