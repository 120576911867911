import { Center, Text } from '@chakra-ui/react';
import moment from 'moment';

type Props = {
    date: string;
};

function CalendarDate(props: Props) {
    return (
        <Center>
            <Text>{moment(props.date).format('ll')}</Text>
        </Center>
    );
}

export default CalendarDate;
