import {
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Stack,
    Text,
} from '@chakra-ui/react';

function About() {
    return (
        <ModalContent>
            <ModalHeader>FAQ</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack spacing={6} mb={6}>
                    <Stack spacing={2}>
                        <Text>Q: What is this website about?</Text>
                        <Text>
                            A: Our website is a platform for coffee lovers to log and share their
                            coffee experiences, create a drinklist of must-try coffees, and discover
                            new brews from Québec.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Text>Q: How do I log a coffee?</Text>
                        <Text>
                            A: To log a coffee, create a drinklist, or give likes, simply create an
                            account on our website (it's free) and navigate to a coffee page. You
                            can enter the roast date or tasting date, and notes about your
                            experience.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Text>Q: Why does your website only feature coffees from Québec?</Text>
                        <Text>
                            A: Our website is focused on celebrating and promoting the coffee
                            culture in Québec. We believe that Québec has a vibrant coffee scene,
                            with a wide variety of unique and delicious coffees that deserve to be
                            highlighted.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Text>
                            Q: What should I do if I find missing coffees or incorrect information
                            on your website?
                        </Text>
                        <Text>
                            A: We strive to provide accurate and up-to-date information on our
                            website, but errors can happen. If you find missing coffees or incorrect
                            information on our website, please let us know by reaching us at{' '}
                            <Text whiteSpace={'nowrap'}>contact _ at _ cafefiltre.ca</Text>
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Text>
                            Q: Do you have any partnerships with coffee shops or roasters in Québec?
                        </Text>
                        <Text>
                            A: While we don't have any official partnerships with coffee shops nor
                            roasters in Québec, we are always open to working with local businesses
                            to promote the coffee culture in the region. If you're a coffee shop
                            owner or manager and would like to collaborate with us, please don't
                            hesitate to get in touch.
                        </Text>
                    </Stack>
                </Stack>
            </ModalBody>
        </ModalContent>
    );
}

export default About;
