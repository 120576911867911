import {
    Button,
    Flex,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { getCurrentUser, postLogout } from '../queries';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { ChevronDownIcon } from '@chakra-ui/icons';
import i18n from '../Languages/Translations';

function UserHeader() {
    const { setUser } = useContext(UserContext);
    const currentUserQuery = getCurrentUser();

    useEffect(() => {
        setUser(currentUserQuery.user?.username ? currentUserQuery.user : undefined);
    }, [currentUserQuery.isFetched]);

    if (!currentUserQuery.isFetched) return null;

    const handleSubmit = async (e: any) => {
        await postLogout();
        window.location.href = '/';
    };

    return (
        <Flex flex="1" gap="2" alignItems="center" flexWrap="wrap">
            {currentUserQuery.user.username ? (
                <Menu>
                    <MenuButton
                        pl={2}
                        size="sm"
                        variant="outline"
                        as={Button}
                        leftIcon={<ChevronDownIcon />}
                    >
                        {currentUserQuery.user.username}
                    </MenuButton>
                    <MenuList>
                        <Link as={ReactRouterLink} to="/profile" style={{ textDecoration: 'none' }}>
                            <MenuItem>{i18n.t('profile')}</MenuItem>
                        </Link>
                        <Link as={ReactRouterLink} to="/diary" style={{ textDecoration: 'none' }}>
                            <MenuItem>{i18n.t('diary')}</MenuItem>
                        </Link>
                        <MenuDivider />
                        {currentUserQuery.user.isAdmin && (
                            <Link
                                as={ReactRouterLink}
                                to="/admin"
                                style={{ textDecoration: 'none' }}
                            >
                                <MenuItem>Admin</MenuItem>
                            </Link>
                        )}
                        <Link
                            as={ReactRouterLink}
                            to="/settings"
                            style={{ textDecoration: 'none' }}
                        >
                            <MenuItem>{i18n.t('settings')}</MenuItem>
                        </Link>
                        <MenuItem onClick={handleSubmit}>{i18n.t('signOut')}</MenuItem>
                    </MenuList>
                </Menu>
            ) : (
                <Link
                    as={ReactRouterLink}
                    to="/settings?login=true"
                    style={{ textDecoration: 'none' }}
                >
                    <Button size={'sm'} variant="ghost">
                        {i18n.t('signIn')}
                    </Button>
                </Link>
            )}
        </Flex>
    );
}

export default UserHeader;
