import CoffeeCard from './CoffeeCard';
import { Box, Center, Divider, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/react';
import { Coffee } from '../types';
import CoffeePagination from './CoffeePagination';
import { useState } from 'react';
import { getCoffeeCount, getCoffees } from '../queries';
import { useSearchParams } from 'react-router-dom';
import CoffeeListFilterButton from './CoffeeListFilterButton';
import CoffeeListSortButton from './CoffeeListSortButton';
import LogoSpinner from '../LogoSpinner';
import i18n from '../Languages/Translations';

type Props = {
    roasterId?: string;
};

function CoffeeList(props: Props) {
    const enablePagination = false;
    const [nbPerPage, setNbPerPage] = useState(enablePagination ? 18 : 10000);
    const [sortOption, setSortOption] = useState('likes');
    const [isSingleOrigin, setIsSingleOrigin] = useState(false);
    const [isDecaffeinated, setIsDecaffeinated] = useState(false);
    const [filterCountries, setFilterCountries] = useState<string[]>([]);
    const [filterProcessingMethods, setFilterProcessingMethods] = useState<string[]>([]);

    const [queryParameters] = useSearchParams();
    const page = Number(queryParameters.get('page') || 1);

    const coffeeQuery = getCoffees(
        props.roasterId,
        props.roasterId ? undefined : page,
        props.roasterId ? undefined : nbPerPage,
        isSingleOrigin,
        isDecaffeinated,
        filterCountries,
        filterProcessingMethods,
        sortOption
    );

    const isFiltered =
        isSingleOrigin ||
        isDecaffeinated ||
        filterCountries.length > 0 ||
        filterProcessingMethods.length > 0;

    const coffeeCountQuery = getCoffeeCount(props.roasterId);

    const paginationComponent = enablePagination &&
        !isFiltered &&
        !props.roasterId &&
        coffeeCountQuery.isFetched && (
            <>
                <Divider mt={2} mb={2} />
                <CoffeePagination
                    coffeeCount={coffeeCountQuery.coffeeCount}
                    currentPage={page}
                    nbPerPage={nbPerPage}
                    setNbPerPage={setNbPerPage}
                />
            </>
        );

    return (
        <>
            <Flex
                gap={4}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <CoffeeListSortButton
                    sortOption={sortOption}
                    setSortOption={setSortOption}
                    roasterId={props.roasterId}
                />
                <CoffeeListFilterButton
                    isSingleOrigin={isSingleOrigin}
                    setIsSingleOrigin={setIsSingleOrigin}
                    isDecaffeinated={isDecaffeinated}
                    setIsDecaffeinated={setIsDecaffeinated}
                    filterCountries={filterCountries}
                    setFilterCountries={setFilterCountries}
                    filterProcessingMethods={filterProcessingMethods}
                    setFilterProcessingMethods={setFilterProcessingMethods}
                />
                <Spacer />
                <Box>
                    {coffeeQuery.isFetched && coffeeCountQuery.isFetched && (
                        <Text ml={4} fontSize={'sm'} fontStyle="italic">
                            {isFiltered && <>{coffeeQuery.coffees.length} / </>}
                            {coffeeCountQuery.coffeeCount} {i18n.t('coffees').toLowerCase()}
                        </Text>
                    )}
                </Box>
            </Flex>
            {coffeeQuery.isFetching ? (
                <Center>
                    <LogoSpinner />
                </Center>
            ) : (
                <>
                    {paginationComponent}
                    <Divider mt={2} mb={2} />
                    <SimpleGrid paddingY={0} minChildWidth="300px" spacing="24px">
                        {coffeeQuery.isFetched &&
                            coffeeQuery.coffees.map((coffee: Coffee, index) => (
                                <CoffeeCard
                                    key={`coffee-card-` + index}
                                    coffee={coffee}
                                    withRoasterName={!props.roasterId}
                                />
                            ))}
                    </SimpleGrid>
                    {paginationComponent}
                </>
            )}
        </>
    );
}

export default CoffeeList;
