import { Box, Center, Icon, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { CiCoffeeBean } from 'react-icons/ci';
import { ImFilter } from 'react-icons/im';

function LogoSpinner() {
    const animationKeyframes = keyframes`
        0% { transform: translateY(-50px) rotate(-135deg) scale(10); }
        75% { transform: translateY(50px) rotate(145deg) scale(7); }
        100% { transform: translateY(100px) rotate(360deg) scale(3); }
        `;

    const animation = `${animationKeyframes} 1.5s infinite ease`;
    return (
        <Center height={'200px'} width={'200px'} mt={2} mb={20}>
            <Box position="absolute" as={motion.div} animation={animation}>
                <Icon pt={1} as={CiCoffeeBean} color={'teal.500'} height={'20px'} width={'20px'} />
            </Box>
            <Box position="absolute" pt={200}>
                <Icon as={ImFilter} color={'teal.500'} height={'120px'} width={'120px'} />
            </Box>
        </Center>
    );
}

export default LogoSpinner;
