import {
    Button,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text,
} from '@chakra-ui/react';
import { TbSortDescending2 } from 'react-icons/tb';
import i18n from '../Languages/Translations';

type Props = {
    sortOption: string;
    setSortOption: any;
    roasterId?: string;
};

function CoffeeListSortButton(props: Props) {
    return (
        <Menu closeOnSelect={true}>
            <MenuButton size="xs" as={Button} color="white">
                <Flex align={'center'}>
                    <Icon as={TbSortDescending2} boxSize={4} mr={2} />
                    <Text fontSize="sm">{i18n.t('sort')}</Text>
                </Flex>
            </MenuButton>
            <MenuList>
                <MenuOptionGroup value={props.sortOption} type="radio">
                    <MenuItemOption value="likes" onClick={() => props.setSortOption('likes')}>
                        {i18n.t('mostPopular')}
                    </MenuItemOption>
                    {!props.roasterId && (
                        <MenuItemOption
                            value="roaster"
                            onClick={() => props.setSortOption('roaster')}
                        >
                            {i18n.t('roaster')}
                        </MenuItemOption>
                    )}
                    <MenuItemOption value="name" onClick={() => props.setSortOption('name')}>
                        {i18n.t('name')}
                    </MenuItemOption>
                    <MenuItemOption
                        value="altitude"
                        onClick={() => props.setSortOption('altitude')}
                    >
                        {i18n.t('altitude')}
                    </MenuItemOption>
                    <MenuItemOption value="date" onClick={() => props.setSortOption('date')}>
                        {i18n.t('recentlyAdded')}
                    </MenuItemOption>
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    );
}

export default CoffeeListSortButton;
