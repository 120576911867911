import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    menuAnatomy.keys
);

const baseStyle = definePartsStyle({
    list: {
        bg: '#3b3b3b',
        textColor: 'white'
    },
    item: {
        bg: '#3b3b3b',
        py: '4px',
        _hover: {
            bg: '#262626',
        },
        _focus: {
            bg: '#262626',
        },
    },
    divider: {
        bg: '#3b3b3b',
    },
});

// export the component theme
export const menuTheme = defineMultiStyleConfig({
    baseStyle,
});
