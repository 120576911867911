import { getCoffeeById } from '../queries';
import CoffeeCardLight from './CoffeeCardLight';

type Props = {
    coffeeId: number;
    size: 'sm' | 'md' | 'lg';
    deleteFn?: () => Promise<any>;
};

function CoffeeCardById(props: Props) {
    const coffeeQuery = getCoffeeById(String(props.coffeeId));

    if (!coffeeQuery.isFetched) {
        return null;
    }

    return (
        <CoffeeCardLight coffee={coffeeQuery.coffee} size={props.size} deleteFn={props.deleteFn} />
    );
}

export default CoffeeCardById;
