import { Badge, Center, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import FavoriteCoffees from '../Coffees/FavoriteCoffees';
import i18n from '../Languages/Translations';
import LogoSpinner from '../LogoSpinner';
import { getUserByName } from '../queries';
import Username from './Username';

function UserProfilePage() {
    const { username } = useParams();

    const userQuery = username ? getUserByName(username) : undefined;

    if (!userQuery || userQuery?.isFetching) {
        return (
            <Center>
                <LogoSpinner />
            </Center>
        );
    }

    return (
        <Center marginY={5}>
            {userQuery.user ? (
                <Stack alignItems={'center'} spacing={6}>
                    <Flex align="center">
                        <Heading textAlign={'center'} size={'md'}>
                            <Username name={userQuery.user.username} />
                        </Heading>
                        {userQuery.user.isAdmin ? (
                            <Badge ml="2" fontSize={14}>
                                {i18n.t('crew')}
                            </Badge>
                        ) : null}
                    </Flex>
                    <Divider />
                    <FavoriteCoffees coffeeIds={userQuery.user.favoriteCoffees} />
                </Stack>
            ) : (
                <Text>{i18n.t('userNotFound')}</Text>
            )}
        </Center>
    );
}

export default UserProfilePage;
