import { Card, Text, Image, Stack, Heading, Center, Flex, Link, Icon } from '@chakra-ui/react';
import { AiFillHeart, AiOutlineFire } from 'react-icons/ai';
import { BiSun } from 'react-icons/bi';
import { CiCoffeeBean, CiLocationArrow1, CiMountain1 } from 'react-icons/ci';
import { RxMixerVertical } from 'react-icons/rx';
import { cardStyles } from '../styles/Card';
import { Coffee, COFFEES_IMG_URL } from '../types';
import { Link as ReactRouterLink } from 'react-router-dom';
import i18n from '../Languages/Translations';

type Props = {
    coffee: Coffee;
    withRoasterName: boolean;
};

function CoffeeCard(props: Props) {
    const coffee = props.coffee;

    const altitudeText =
        coffee.altitudeMin === coffee.altitudeMax
            ? `${coffee.altitudeMin} masl`
            : `${coffee.altitudeMin}-${coffee.altitudeMax} masl`;

    return (
        <Link as={ReactRouterLink} to={`/coffees/${coffee.id}`} style={{ textDecoration: 'none' }}>
            <Card height={'160px'} {...cardStyles()} p="12px" justifyContent={'center'}>
                <Flex align={'top'}>
                    <Center width={'33%'} mx={2} mr={4}>
                        <Stack textAlign={'center'} alignItems={'center'}>
                            <Image
                                src={`${COFFEES_IMG_URL}/${coffee.id}_small.png`}
                                fallbackSrc={coffee.imageUrl}
                                fallbackStrategy="onError"
                                alt={'Logo of ' + coffee.name}
                                borderRadius="lg"
                                height="100px"
                                width="100px"
                                objectFit="cover"
                            />
                            <Flex align={'center'}>
                                <Icon as={AiFillHeart} boxSize={3} mr={2} />
                                <Text fontSize="xs" noOfLines={1}>
                                    {coffee.likeCount}
                                </Text>
                            </Flex>
                        </Stack>
                    </Center>
                    <Stack width={'67%'} textAlign={'left'}>
                        {props.withRoasterName ? (
                            <Flex align={'center'}>
                                <Icon as={AiOutlineFire} mr={2} />
                                <Heading size="sm" noOfLines={1}>
                                    {coffee.roasterName}
                                </Heading>
                            </Flex>
                        ) : null}
                        <Flex align={'center'}>
                            <Icon as={CiCoffeeBean} mr={2} />
                            <Heading size="sm" noOfLines={1}>
                                {coffee.name}
                            </Heading>
                        </Flex>
                        {coffee.isSingleOrigin ? (
                            <>
                                <Flex align={'center'}>
                                    <Icon as={CiLocationArrow1} mr={2} />
                                    <Text fontSize="sm" noOfLines={1}>
                                        {i18n.defaultLocale === 'en'
                                            ? coffee.countryEn
                                            : coffee.countryFr}
                                    </Text>
                                </Flex>
                                {coffee.processingMethodEn !== 'Unknown' ? (
                                    <Flex align={'center'}>
                                        <Icon as={BiSun} mr={2} />
                                        <Text noOfLines={1} fontSize="sm">
                                            {i18n.defaultLocale === 'en'
                                                ? coffee.processingMethodEn
                                                : coffee.processingMethodFr}
                                            {coffee.isDecaf ? (
                                                <>
                                                    {' '}
                                                    - <i>{i18n.t('decaffeinated')}</i>
                                                </>
                                            ) : null}
                                        </Text>
                                    </Flex>
                                ) : null}
                                {altitudeText !== '0 masl' ? (
                                    <Flex align={'center'}>
                                        <Icon as={CiMountain1} mr={2} />
                                        <Text fontSize="sm">{altitudeText}</Text>
                                    </Flex>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Flex align={'center'}>
                                    <Icon as={RxMixerVertical} mr={2} />
                                    <Text noOfLines={1} fontSize="sm">
                                        {i18n.t('blend')}
                                        {coffee.isDecaf ? (
                                            <>
                                                {' '}
                                                - <i>{i18n.t('decaffeinated')}</i>
                                            </>
                                        ) : null}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Stack>
                </Flex>
            </Card>
        </Link>
    );
}

export default CoffeeCard;
