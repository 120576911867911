import {
    Card,
    Text,
    Stack,
    Heading,
    Flex,
    LinkBox,
    LinkOverlay,
    Icon,
    Center,
    Box,
} from '@chakra-ui/react';
import RoasterLogo from './RoasterLogo';
import { Roaster } from '../types';
import { AiOutlineFire } from 'react-icons/ai';
import { CiCoffeeBean, CiLocationArrow1 } from 'react-icons/ci';
import { Link as ReactRouterLink } from 'react-router-dom';
import { cardStyles } from '../styles/Card';
import i18n from '../Languages/Translations';

type Props = {
    roaster: Roaster;
};

function RoasterCard(props: Props) {
    const roaster = props.roaster;

    return (
        <LinkBox>
            <Card height={'180px'} {...cardStyles()} p="12px" justifyContent={'center'}>
                <Flex align={'center'}>
                    <Center width={'45%'} marginX={2} marginRight={4}>
                        <RoasterLogo roaster={roaster} maxHeight={'150'} width={'100%'} />
                    </Center>
                    <Box width={'55%'}>
                        <Flex align="center" padding={2}>
                            <Stack textAlign={'left'}>
                                <Flex align={'center'}>
                                    <Icon as={AiOutlineFire} marginRight={'2'} />
                                    <LinkOverlay
                                        as={ReactRouterLink}
                                        to={`/roasters/${roaster.id}/`}
                                    >
                                        <Heading size="md">{roaster.name}</Heading>
                                    </LinkOverlay>
                                </Flex>
                                <Flex align={'center'}>
                                    <Icon as={CiLocationArrow1} marginRight={'2'} />
                                    <Text fontSize="sm">
                                        {roaster.city}, {roaster.province}
                                    </Text>
                                </Flex>
                                <Flex align={'center'}>
                                    <Icon as={CiCoffeeBean} marginRight={'2'} />
                                    <Text fontSize="sm">
                                        {roaster.coffeeCount}{' '}
                                        {roaster.coffeeCount === 1
                                            ? i18n.t('coffee').toLowerCase()
                                            : i18n.t('coffees').toLowerCase()}
                                    </Text>
                                </Flex>
                            </Stack>
                        </Flex>
                    </Box>
                </Flex>
            </Card>
        </LinkBox>
    );
}

export default RoasterCard;
