import {
    Box,
    Button,
    Divider,
    Heading,
    HStack,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    Textarea,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { Coffee, DateType } from '../types';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Users/UserContext';
import { useQueryClient } from 'react-query';
import { getCoffeeLogs, postCoffeeLog } from '../queries';
import { MdCoffee, MdOutlineCoffee } from 'react-icons/md';
import CoffeeLogTable from '../Diary/CoffeeLogTable';
import i18n from '../Languages/Translations';

type Props = {
    coffee: Coffee;
};

function CoffeeLogButton(props: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const queryClient = useQueryClient();

    const { user } = useContext(UserContext);
    const userFound = !!user?.username;

    const coffeeLogsQuery = getCoffeeLogs(props.coffee.id);

    const getCurrentDate = () => {
        const dt = new Date();
        const year = dt.toLocaleString('default', { year: 'numeric' });
        const month = dt.toLocaleString('default', { month: '2-digit' });
        const day = dt.toLocaleString('default', { day: '2-digit' });
        return `${year}-${month}-${day}`;
    };

    const [isLogged, setIsLogged] = useState(false);
    const [isHovering, setHover] = useState(false);
    const [date, setDate] = useState(getCurrentDate());
    const [dateType, setDateType] = useState<DateType>('ROAST');
    const [notes, setNotes] = useState('');

    useEffect(() => {
        setIsLogged(coffeeLogsQuery.coffeeLogs?.length !== 0);
    }, [coffeeLogsQuery.coffeeLogs]);

    if (!coffeeLogsQuery.isFetched) {
        return null;
    }

    const coffeeLogs = [...coffeeLogsQuery.coffeeLogs].sort((a, b) => (a.date < b.date ? 1 : -1));

    const closeModal = () => {
        setDate(getCurrentDate());
        setDateType('ROAST');
        setNotes('');
        onClose();
    };

    const logAndClose = async (e: any) => {
        await postCoffeeLog({
            coffeeId: props.coffee.id,
            date: date,
            dateType: dateType,
            notes: notes,
        });
        closeModal();
        queryClient.invalidateQueries('coffee-logs');
        toast({
            title: i18n.t('coffeeLogged'),
            status: 'info',
            duration: 3000,
            variant: 'subtle',
            isClosable: true,
        });
    };

    const getDateTypeString = (dateType: DateType) => {
        switch (dateType) {
            case 'ROAST':
                return i18n.t('roastDate');
            case 'DRINK':
            default:
                return i18n.t('tastingDate');
        }
    };

    return (
        <>
            <VStack
                width={'75px'}
                pointerEvents={userFound ? 'auto' : 'none'}
                opacity={userFound ? '1' : '0.5'}
                cursor="pointer"
                _hover={{ color: 'white' }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={onOpen}
            >
                {isLogged ? (
                    <>
                        <Icon boxSize={6} as={MdCoffee} color={'teal.500'} />
                        <Text fontSize="sm" userSelect="none">
                            {isHovering ? <>{i18n.t('edit')}</> : <>{i18n.t('logged')}</>}
                        </Text>
                    </>
                ) : (
                    <>
                        <Icon boxSize={6} as={MdOutlineCoffee} />
                        <Text fontSize="sm" userSelect="none">
                            {i18n.t('log')}
                        </Text>
                    </>
                )}
            </VStack>
            <Modal onClose={closeModal} isOpen={isOpen} isCentered>
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px) hue-rotate(10deg)" />
                <ModalContent>
                    <ModalHeader fontSize={'md'}>
                        {props.coffee.roasterName} - {props.coffee.name}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isLogged && (
                            <Box mb={4}>
                                <Heading fontSize="sm">{i18n.t('editLogs')}</Heading>
                                <Divider my={1} />
                                <CoffeeLogTable coffeeLogs={coffeeLogs} showAllLogs={false} />
                            </Box>
                        )}
                        <Heading fontSize="sm">{i18n.t('newLog')}</Heading>
                        <Divider my={1} />
                        <HStack mb={1}>
                            <Select
                                size="sm"
                                value={dateType}
                                onChange={(e: any) => setDateType(e.target.value)}
                                bgColor="#323232"
                            >
                                <option value="ROAST">{getDateTypeString('ROAST')}</option>
                                <option value="DRINK">{getDateTypeString('DRINK')}</option>
                            </Select>
                            <Input
                                placeholder={i18n.t('selectDate')}
                                size="sm"
                                type="date"
                                value={date}
                                onChange={(e: any) => setDate(e.target.value)}
                                bgColor="#323232"
                            />
                        </HStack>
                        <Textarea
                            placeholder={i18n.t('notes')}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            bgColor="#323232"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" onClick={logAndClose}>
                            {i18n.t('log')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default CoffeeLogButton;
