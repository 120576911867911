import { Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import AdminSubmitButton from './AdminSubmitButton';
import { postCity } from '../queries';

function AdminCities() {
    const handleFocus = (e: any) => e.target.select();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [province, setProvince] = useState('');

    const handleId = (e: any) => {
        setId(e.target.value);
    };

    const handleName = (e: any) => {
        setName(e.target.value);
    };

    const handleProvince = (e: any) => {
        setProvince(e.target.value);
    };

    return (
        <Stack spacing={8}>
            <Stack spacing={2}>
                <Text>ID</Text>
                <Input placeholder="CHI" value={id} onChange={handleId} onFocus={handleFocus} />
            </Stack>
            <Stack spacing={2}>
                <Text>Name</Text>
                <Input
                    placeholder="Chicoutimi"
                    value={name}
                    onChange={handleName}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={2}>
                <Text>Province</Text>
                <Input
                    placeholder="QC"
                    value={province}
                    onChange={handleProvince}
                    onFocus={handleFocus}
                />
            </Stack>
            <AdminSubmitButton
                newObject={{
                    id,
                    name,
                    province
                }}
                postQuery={postCity}
            />
        </Stack>
    );
}

export default AdminCities;
