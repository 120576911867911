import RoasterCard from './RoasterCard';
import { SimpleGrid } from '@chakra-ui/react';
import { Roaster } from '../types';

type Props = {
    roasters: Roaster[];
};

function RoasterList(props: Props) {
    return (
        <SimpleGrid paddingY={0} minChildWidth="350px" spacing="24px">
            {props.roasters.map((roaster: Roaster, index) => (
                <RoasterCard key={`roaster-card-` + index} roaster={roaster} />
            ))}
        </SimpleGrid>
    );
}

export default RoasterList;
