import { Box, Container } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import RoasterPage from './Roasters/RoasterPage';
import HomePage from './HomePage';
import RoastersPage from './Roasters/RoastersPage';
import CoffeesPage from './Coffees/CoffeesPage';
import AdminPage from './Admin/AdminPage';
import UserProfilePage from './Users/UserProfilePage';
import UserProfileEditPage from './Users/UserProfileEditPage';
import NotFound from './NotFound';
import CoffeePage from './Coffees/CoffeePage';
import { UserContext } from './Users/UserContext';
import { useState } from 'react';
import { UserPrivate } from './types';
import UsersPage from './Users/UsersPage';
import SettingsPage from './Users/SettingsPage';
import UserDiary from './Diary/UserDiary';
import i18n from './Languages/Translations';

function App() {
    const [user, setUser] = useState<UserPrivate | undefined>(undefined);
    const [, setLang] = useState(i18n.defaultLocale);
    i18n.onChange(() => {
        setLang(i18n.defaultLocale);
    });
    return (
        <BrowserRouter>
            <UserContext.Provider value={{ user, setUser }}>
                <Box minHeight={'100vh'} background={'linear-gradient(#1e1e1e, #111420)'}>
                    <AppHeader />
                    <Container maxW="1000px" textColor={'#abc'}>
                        <Box as="main">
                            <Routes>
                                <Route path="/" element={<HomePage key={'route_home'} />} />
                                <Route
                                    path="/roasters"
                                    element={<RoastersPage key={'route_roasters'} />}
                                />
                                <Route
                                    path="/roasters/:id"
                                    element={<RoasterPage key={'route_roaster'} />}
                                />
                                <Route
                                    path="/coffees"
                                    element={<CoffeesPage key={'route_coffees'} />}
                                />
                                <Route
                                    path="/coffees/:id"
                                    element={<CoffeePage key={'route_coffee'} />}
                                />
                                <Route path="/users" element={<UsersPage key={'route_users'} />} />
                                <Route
                                    path="/users/:username"
                                    element={<UserProfilePage key={'route_user'} />}
                                />
                                <Route
                                    path="/profile"
                                    element={<UserProfileEditPage key={'route_profile'} />}
                                />
                                <Route path="/diary" element={<UserDiary key={'route_diary'} />} />
                                <Route
                                    path="/settings"
                                    element={<SettingsPage key={'route_settings'} />}
                                />
                                <Route path="/admin" element={<AdminPage key={'route_admin'} />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Box>
                        <AppFooter />
                    </Container>
                </Box>
            </UserContext.Provider>
        </BrowserRouter>
    );
}

export default App;
