import axios from 'axios';
import { useQuery } from 'react-query';
import {
    Coffee,
    Country,
    Roaster,
    RoasterPost,
    ProcessingMethod,
    City,
    UserPrivate,
    UserPublic,
    CoffeeLog,
    CoffeeLogPost,
    CoffeeLogPut,
} from './types';

const API_URL = () => {
    let apiURL = `${window.location.origin}/api`;
    if (window.location.port === '3000') {
        apiURL = `http://${window.location.hostname}:3001/api`;
    }
    return apiURL;
};

export const getRoasters = (filtered = true) => {
    const query = useQuery('roasters', () =>
        axios.get(`${API_URL()}/roasters`).then((res) => res.data)
    );

    let roasters: Roaster[] = query.data || [];
    if (filtered) {
        roasters = roasters.filter((roaster) => roaster.coffeeCount !== 0);
    }
    return { roasters, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getRoasterById = (id: string) => {
    const query = useQuery(['roasters', id], () =>
        axios.get(`${API_URL()}/roasters/${id}`).then((res) => res.data)
    );
    const roaster = query.data;
    return { roaster, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getCoffees = (
    roasterId: string | undefined,
    page: number | undefined,
    count: number | undefined,
    isSingleOrigin: boolean,
    isDecaffeinated: boolean,
    filterCountries: string[],
    filterProcessingMethods: string[],
    sortBy: string
) => {
    const roasterParam = roasterId ? `roasterId=${roasterId}&` : '';
    const pageParam = page ? `page=${page}&` : '';
    const countParam = count ? `count=${count}&` : '';
    const isSingleOriginParam = isSingleOrigin ? 'isSingleOrigin=true&' : '';
    const isDecaffeinatedParam = isDecaffeinated ? 'isDecaffeinated=true&' : '';
    const filterCountriesParam =
        filterCountries.length > 0 ? `countries=${filterCountries.join(',')}&` : '';
    const filterPmParams =
        filterProcessingMethods.length > 0
            ? `processingMethods=${filterProcessingMethods.join(',')}&`
            : '';
    const sortByParam = `sortBy=${sortBy}`;
    let queryParams = roasterParam + pageParam + countParam;
    queryParams = queryParams + isSingleOriginParam + isDecaffeinatedParam;
    queryParams = queryParams + filterCountriesParam + filterPmParams + sortByParam;

    const query = useQuery(
        [
            'coffees',
            roasterId,
            page,
            count,
            isSingleOrigin,
            isDecaffeinated,
            filterCountries,
            filterProcessingMethods,
            sortBy,
        ],
        () => axios.get(`${API_URL()}/coffees?${queryParams}`).then((res) => res.data)
    );
    const coffees: Coffee[] = query.data || [];
    return { coffees, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getCoffeeCount = (roasterId?: string) => {
    const roasterParam = roasterId ? `?roasterId=${roasterId}` : '';
    const query = useQuery(['coffeeCount', roasterId], () =>
        axios.get(`${API_URL()}/coffees/count${roasterParam}`).then((res) => res.data)
    );
    const coffeeCount = query.data;
    return { coffeeCount, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getCoffeeById = (id: string) => {
    const query = useQuery(['coffee', id], () =>
        axios.get(`${API_URL()}/coffees/${id}`).then((res) => res.data)
    );
    const coffee = query.data;
    return { coffee, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getLatestCoffees = () => {
    const query = useQuery(['coffees', 'latest'], () =>
        axios.get(`${API_URL()}/coffees/latest`).then((res) => res.data)
    );
    const coffees: Coffee[] = query.data || [];
    return { coffees, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getPopularCoffees = () => {
    const query = useQuery(['coffees', 'popular'], () =>
        axios.get(`${API_URL()}/coffees/popular`).then((res) => res.data)
    );
    const coffees: Coffee[] = query.data || [];
    return { coffees, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getCountries = (showAll: boolean = false) => {
    const query = useQuery('countries', () =>
        axios.get(`${API_URL()}/countries${showAll ? '?showAll=true' : ''}`).then((res) => res.data)
    );
    const countries: Country[] = query.data || [];
    return { countries, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getCities = () => {
    const query = useQuery('cities', () =>
        axios.get(`${API_URL()}/cities`).then((res) => res.data)
    );
    const cities: City[] = query.data || [];
    return { cities, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getProcessingMethods = () => {
    const query = useQuery('processing-methods', () =>
        axios.get(`${API_URL()}/processing-methods`).then((res) => res.data)
    );
    const processingMethods: ProcessingMethod[] = query.data || [];
    return { processingMethods, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const postProcessingMethod = async (processingMethod: ProcessingMethod) => {
    const res = await fetch(`${API_URL()}/processing-methods`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(processingMethod),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const postCity = async (city: City) => {
    const res = await fetch(`${API_URL()}/cities`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(city),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const postCoffee = async (coffee: Coffee) => {
    const res = await fetch(`${API_URL()}/coffees`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(coffee),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const postRoaster = async (roaster: RoasterPost) => {
    const res = await fetch(`${API_URL()}/roasters`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(roaster),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const postLogin = async (username: string, password: string) => {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    const res = await fetch(`${API_URL()}/login/password`, {
        method: 'post',
        body: data,
        credentials: 'include',
    });
    return res;
};

export const postRegister = async (
    mail: string,
    username: string,
    password: string
) => {
    const res = await fetch(`${API_URL()}/register`, {
        method: 'post',
        body: JSON.stringify({
            mail: mail,
            username: username,
            password: password,
        }),
        headers: { 'Content-Type': 'application/json' },
    });
    return res;
};

export const postLogout = async () => {
    const res = await fetch(`${API_URL()}/logout`, {
        method: 'post',
        credentials: 'include',
    });
    return res;
};

export const getCurrentUser = () => {
    const query = useQuery('current-user', () =>
        axios.get(`${API_URL()}/current-user`, { withCredentials: true }).then((res) => res.data)
    );
    const user: UserPrivate = query.data;
    return { user, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getUsers = () => {
    const query = useQuery(['users'], () =>
        axios.get(`${API_URL()}/users`).then((res) => res.data)
    );
    const users: UserPublic[] = query.data || [];
    return { users, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getUserByName = (username: string) => {
    const query = useQuery(['userByName', username], () =>
        axios.get(`${API_URL()}/user/${username}`).then((res) => res.data)
    );
    const user: UserPublic = query.data;
    return { user, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const searchCoffee = async (query: string, coffeesOnly: boolean) => {
    const res = await fetch(`${API_URL()}/search${coffeesOnly ? '?coffeesOnly=true' : ''}`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify({ query: query }),
        headers: { 'Content-Type': 'application/json' },
    });
    return res;
};

export const getFavorites = () => {
    const query = useQuery('favorites', () =>
        axios.get(`${API_URL()}/favorites`, { withCredentials: true }).then((res) => res.data)
    );
    const coffeeIds: number[] = query.data || [];
    return { coffeeIds, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const addToFavorites = async (index: number, coffeeId: number) => {
    const res = await fetch(`${API_URL()}/favorites?index=${index}&coffeeId=${coffeeId}`, {
        method: 'put',
        credentials: 'include',
    });
    return res;
};

export const removeFromFavorites = async (index: number) => {
    const res = await fetch(`${API_URL()}/favorites/${index}`, {
        method: 'delete',
        credentials: 'include',
    });
    return res;
};

export const getLikedCoffees = () => {
    const query = useQuery('likes', () =>
        axios.get(`${API_URL()}/likes`, { withCredentials: true }).then((res) => res.data)
    );
    const coffees: Coffee[] = query.data || [];
    return { coffees, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getIsCoffeeLiked = (coffeeId: number) => {
    const query = useQuery(['likes', coffeeId], () =>
        axios
            .get(`${API_URL()}/likes/${coffeeId}`, { withCredentials: true })
            .then((res) => res.data)
    );
    const isCoffeeLiked: boolean = query.data;
    return { isCoffeeLiked, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const putLike = async (coffeeId: number) => {
    const res = await fetch(`${API_URL()}/likes/${coffeeId}`, {
        method: 'put',
        credentials: 'include',
    });
    return res;
};

export const deleteLike = async (coffeeId: number) => {
    const res = await fetch(`${API_URL()}/likes/${coffeeId}`, {
        method: 'delete',
        credentials: 'include',
    });
    return res;
};

export const getDrinklist = () => {
    const query = useQuery('drinklist', () =>
        axios.get(`${API_URL()}/drinklist`, { withCredentials: true }).then((res) => res.data)
    );
    const coffees: Coffee[] = query.data || [];
    return { coffees, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const getIsInDrinklist = (coffeeId: number) => {
    const query = useQuery(['drinklist', coffeeId], () =>
        axios
            .get(`${API_URL()}/drinklist/${coffeeId}`, { withCredentials: true })
            .then((res) => res.data)
    );
    const isCoffeeInDrinklist: boolean = query.data;
    return { isCoffeeInDrinklist, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const putInDrinklist = async (coffeeId: number) => {
    const res = await fetch(`${API_URL()}/drinklist/${coffeeId}`, {
        method: 'put',
        credentials: 'include',
    });
    return res;
};

export const removeFromDrinklist = async (coffeeId: number) => {
    const res = await fetch(`${API_URL()}/drinklist/${coffeeId}`, {
        method: 'delete',
        credentials: 'include',
    });
    return res;
};

export const postCoffeeLog = async (coffeeLog: CoffeeLogPost) => {
    const res = await fetch(`${API_URL()}/coffee-logs`, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(coffeeLog),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const getCoffeeLogs = (coffeeId?: number) => {
    const apiPath = coffeeId ? `coffees/${coffeeId}/coffee-logs` : 'coffee-logs';
    const query = useQuery(['coffee-logs', coffeeId], () =>
        axios.get(`${API_URL()}/${apiPath}`, { withCredentials: true }).then((res) => res.data)
    );
    const coffeeLogs: CoffeeLog[] = query.data || [];
    return { coffeeLogs, isFetching: query.isFetching, isFetched: query.isFetched };
};

export const putCoffeeLogs = async (logId: number, coffeeLog: CoffeeLogPut) => {
    const res = await fetch(`${API_URL()}/coffee-logs/${logId}`, {
        method: 'put',
        cache: 'no-cache',
        body: JSON.stringify(coffeeLog),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
    return res;
};

export const deleteCoffeeLog = async (logId: number) => {
    const res = await fetch(`${API_URL()}/coffee-logs/${logId}`, {
        method: 'delete',
        credentials: 'include',
    });
    return res;
};
