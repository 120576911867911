import { AtSignIcon, EmailIcon } from '@chakra-ui/icons';
import { Center, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from '../Languages/Translations';
import Login from './Login';
import Register from './Register';
import { UserContext } from './UserContext';

function SettingsPage() {
    const [queryParameters] = useSearchParams();
    if (queryParameters.get('mailSent') === 'true') {
        return (
            <Center marginY={5} textAlign="center">
                <Text size={'md'}>{i18n.t('verifMailSent')}</Text>
            </Center>
        );
    }

    const isLoginPage = !!queryParameters.get('login');

    const { user } = useContext(UserContext);

    const pageComponent = isLoginPage ? <Login /> : <Register />;

    return user?.username ? (
        <Center marginY={5}>
            <Stack alignItems={'left'}>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<AtSignIcon color="gray.300" />}
                    />
                    <Input variant="filled" value={user.username} isDisabled />
                </InputGroup>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<EmailIcon color="gray.300" />}
                    />
                    <Input variant="filled" value={user.mail} isDisabled />
                </InputGroup>
            </Stack>
        </Center>
    ) : (
        pageComponent
    );
}

export default SettingsPage;
