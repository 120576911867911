import {
    Button,
    Center,
    Heading,
    Input,
    Link,
    Text,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import i18n from '../Languages/Translations';
import { postLogin } from '../queries';

function Login() {
    const [queryParameters] = useSearchParams();
    const isLoginFailed = queryParameters.get('login') === 'failed';
    const isVerified = queryParameters.get('verified') === 'true';

    const [username, setUsername] = useState('');

    const handleUsername = (e: any) => {
        setUsername(e.target.value);
    };

    const [password, setPassword] = useState('');

    const handlePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e: any) => {
        const response = await postLogin(username, password);
        if (!response.ok) {
            window.location.href = '/settings?login=failed';
        } else {
            window.location.href = '/';
        }
    };

    return (
        <Center marginY={5}>
            <Stack alignItems={'center'} spacing={8}>
                <Heading size={'md'}>
                    {isVerified ? i18n.t('accountVerified') : i18n.t('alreadyHaveAccount')}
                </Heading>
                <FormControl w={350} isRequired isInvalid={isLoginFailed}>
                    <FormLabel mt={4}>{i18n.t('usernameOrMail')}</FormLabel>
                    <Input
                        type={'username'}
                        value={username}
                        onChange={handleUsername}
                        required
                        autoFocus
                        autoComplete="username"
                    />
                    <FormLabel mt={4}>{i18n.t('password')}</FormLabel>
                    <Input
                        type={'password'}
                        value={password}
                        onChange={handlePassword}
                        required
                        autoComplete="current-password"
                    />
                    <Center>
                        <Button
                            mt={4}
                            loadingText="Loading"
                            spinnerPlacement="start"
                            onClick={handleSubmit}
                        >
                            {i18n.t('signIn')}
                        </Button>
                    </Center>
                    <FormErrorMessage>{i18n.t('usernamePwIncorrect')}</FormErrorMessage>
                </FormControl>
                <Text>
                    <Link as={ReactRouterLink} to={'/settings?register=true'}>
                        {i18n.t('createAccountInstead')}
                    </Link>
                </Text>
            </Stack>
        </Center>
    );
}

export default Login;
