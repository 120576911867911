import { DeleteIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Center,
    HStack,
    Icon,
    Input,
    Link,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    useMediaQuery,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsCardText } from 'react-icons/bs';
import { GiFireBowl } from 'react-icons/gi';
import { MdCoffee } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import CoffeeCardById from '../Coffees/CoffeeCardById';
import { deleteCoffeeLog, putCoffeeLogs } from '../queries';
import { CoffeeLog, DateType } from '../types';
import CalendarDate from './CalendarDate';
import { Link as ReactRouterLink } from 'react-router-dom';
import i18n from '../Languages/Translations';

type Props = {
    coffeeLogs: CoffeeLog[];
    showAllLogs: boolean;
};

function CoffeeLogTable(props: Props) {
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    const toast = useToast();
    const queryClient = useQueryClient();

    const [isDeleting, setIsDeleting] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editedNotes, setEditedNotes] = useState('');

    const getDateTypeString = (dateType: DateType) => {
        switch (dateType) {
            case 'ROAST':
                return i18n.t('roastDate');
            case 'DRINK':
            default:
                return i18n.t('tastingDate');
        }
    };

    const handleEdit = async (log: CoffeeLog) => {
        setEditedNotes(log.notes);
        setIsEditing(true);
    };

    const handleSave = async (log: CoffeeLog) => {
        await putCoffeeLogs(log.id, {
            date: log.date,
            dateType: log.dateType,
            notes: editedNotes,
        });
        setEditedNotes('');
        setIsEditing(false);
        toast({
            title: i18n.t('notesEdited'),
            status: 'info',
            duration: 3000,
            variant: 'subtle',
            isClosable: true,
        });
        queryClient.invalidateQueries('coffee-logs');
    };

    const handleCancel = async () => {
        setEditedNotes('');
        setIsEditing(false);
    };

    const handleDelete = async (logId: number) => {
        setIsDeleting(true);
        await deleteCoffeeLog(logId);
        setIsDeleting(false);
        queryClient.invalidateQueries('coffee-logs');
    };

    const updateDateType = async (log: CoffeeLog, value: string) => {
        await putCoffeeLogs(log.id, {
            date: log.date,
            dateType: value as DateType,
            notes: log.notes,
        });
        toast({
            title: i18n.t('dateEdited'),
            status: 'info',
            duration: 3000,
            variant: 'subtle',
            isClosable: true,
        });
        queryClient.invalidateQueries('coffee-logs');
    };

    const updateDate = async (log: CoffeeLog, value: string) => {
        await putCoffeeLogs(log.id, {
            date: value,
            dateType: log.dateType,
            notes: log.notes,
        });
        toast({
            title: i18n.t('dateEdited'),
            status: 'info',
            duration: 3000,
            variant: 'subtle',
            isClosable: true,
        });
        queryClient.invalidateQueries('coffee-logs');
    };

    return (
        <Center>
            <TableContainer>
                <Table size="sm" variant={'unstyled'}>
                    <Tbody>
                        {props.coffeeLogs.map((log) => (
                            <Tr key={`tr_${log.id}`}>
                                <Td>
                                    <Popover placement="top">
                                        <PopoverTrigger>
                                            <Button
                                                p={2}
                                                height={'max-content'}
                                                size="sm"
                                                textColor={'#abc'}
                                                variant="ghost"
                                            >
                                                <VStack>
                                                    <Tooltip
                                                        hasArrow
                                                        label={getDateTypeString(log.dateType)}
                                                        placement="top"
                                                    >
                                                        <span>
                                                            <Icon
                                                                boxSize={5}
                                                                as={
                                                                    log.dateType === 'ROAST'
                                                                        ? GiFireBowl
                                                                        : MdCoffee
                                                                }
                                                                color={'teal.500'}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                    <CalendarDate date={log.date} />
                                                </VStack>
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow bgColor="#3b3b3b" />
                                            <HStack m={2}>
                                                <Select
                                                    size="sm"
                                                    value={log.dateType}
                                                    onChange={(e: any) =>
                                                        updateDateType(log, e.target.value)
                                                    }
                                                    bgColor="#323232"
                                                >
                                                    <option value="ROAST">
                                                        {getDateTypeString('ROAST')}
                                                    </option>
                                                    <option value="DRINK">
                                                        {getDateTypeString('DRINK')}
                                                    </option>
                                                </Select>
                                                <Input
                                                    placeholder="Select date"
                                                    size="sm"
                                                    type="date"
                                                    value={log.date}
                                                    onChange={(e: any) =>
                                                        updateDate(log, e.target.value)
                                                    }
                                                    bgColor="#323232"
                                                />
                                            </HStack>
                                        </PopoverContent>
                                    </Popover>
                                </Td>
                                {props.showAllLogs && (
                                    <>
                                        <Td>
                                            <CoffeeCardById coffeeId={log.coffeeId} size={'sm'} />
                                        </Td>
                                        {!isMobile && (
                                            <>
                                                <Td>
                                                    <Link
                                                        as={ReactRouterLink}
                                                        to={`/roasters/${log.roasterId}`}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <Text fontSize={14}>{log.roasterName}</Text>
                                                    </Link>
                                                </Td>
                                                <Td>
                                                    <Link
                                                        as={ReactRouterLink}
                                                        to={`/coffees/${log.coffeeId}`}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <Text fontSize={14}>{log.coffeeName}</Text>
                                                    </Link>
                                                </Td>
                                            </>
                                        )}
                                    </>
                                )}
                                <Td>
                                    <Popover placement="top">
                                        <PopoverTrigger>
                                            <Button size="sm" variant="ghost">
                                                <Icon
                                                    mr={isMobile ? 0 : 2}
                                                    boxSize={6}
                                                    as={BsCardText}
                                                    color={'teal.500'}
                                                />
                                                {!isMobile && <>{i18n.t('notes')}</>}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow bgColor="#3b3b3b" />
                                            <VStack m={2}>
                                                <Textarea
                                                    placeholder={i18n.t('notes')}
                                                    isReadOnly={!isEditing}
                                                    value={isEditing ? editedNotes : log.notes}
                                                    onChange={(e) => setEditedNotes(e.target.value)}
                                                    bgColor="#323232"
                                                    textColor={isEditing ? '#white' : '#abc'}
                                                />
                                                <ButtonGroup
                                                    size="sm"
                                                    variant="outline"
                                                    spacing={6}
                                                >
                                                    {!isEditing ? (
                                                        <Button onClick={() => handleEdit(log)}>
                                                            {i18n.t('edit')}
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                colorScheme="teal"
                                                                onClick={() => handleSave(log)}
                                                            >
                                                                {i18n.t('save')}
                                                            </Button>
                                                            <Button onClick={handleCancel}>
                                                                {i18n.t('cancel')}
                                                            </Button>
                                                        </>
                                                    )}
                                                </ButtonGroup>
                                            </VStack>
                                        </PopoverContent>
                                    </Popover>
                                </Td>
                                <Td>
                                    <Popover placement="top">
                                        <PopoverTrigger>
                                            <Button
                                                size="xs"
                                                height={6}
                                                width={6}
                                                borderRadius={50}
                                                bgColor={'red.700'}
                                                variant="outline"
                                                _hover={{
                                                    bgColor: 'red.500',
                                                }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent w={'fit-content'}>
                                            <PopoverArrow bgColor="#3b3b3b" />
                                            <Button
                                                size="xs"
                                                variant="ghost"
                                                onClick={() => handleDelete(log.id)}
                                                isLoading={isDeleting}
                                                _hover={{
                                                    bgColor: 'orange.500',
                                                }}
                                            >
                                                {i18n.t('delete')}
                                            </Button>
                                        </PopoverContent>
                                    </Popover>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Center>
    );
}

export default CoffeeLogTable;
