const isTouchDevice = () => {
    return navigator.maxTouchPoints > 0;
};

export const cardStyles = () => {
    return {
        backgroundColor: '#303030',
        borderWidth: '1px',
        borderColor: '#000000',
        borderRadius: '18px',
        _hover: !isTouchDevice() ? { borderColor: 'white', color: 'teal.500' } : {},
    };
};
