import { Box, Center, SimpleGrid } from '@chakra-ui/react';
import DefaultCard from '../Coffees/DefaultCard';
import CoffeeCardById from '../Coffees/CoffeeCardById';
import { getFavorites, removeFromFavorites } from '../queries';

function FavoriteCoffeesEdit() {
    const favoritesQuery = getFavorites();

    if (!favoritesQuery.isFetched) {
        return null;
    }

    return (
        <Center>
            <SimpleGrid columns={[2, null, 4]} spacing="20px">
                {favoritesQuery.coffeeIds.map((id, index) => (
                    <Box key={`fav_${index}`}>
                        {id ? (
                            <CoffeeCardById
                                coffeeId={id}
                                size={'lg'}
                                deleteFn={() => removeFromFavorites(index)}
                            />
                        ) : (
                            <DefaultCard index={index} withAdd />
                        )}
                    </Box>
                ))}
            </SimpleGrid>
        </Center>
    );
}

export default FavoriteCoffeesEdit;
