import { Box, Center, Divider } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import RoasterLogo from './RoasterLogo';
import { getRoasterById } from '../queries';
import CoffeeList from '../Coffees/CoffeeList';

function RoasterPage() {
    const { id } = useParams();
    const roasterQuery = id && getRoasterById(id);
    if (!roasterQuery || roasterQuery.isFetching) {
        return null;
    }

    return (
        <Box paddingTop="4">
            <Center paddingBottom={4}>
                <a target="_blank" href={roasterQuery.roaster.siteUrl}>
                    <RoasterLogo roaster={roasterQuery.roaster} maxHeight={'150'} width={'400'} />
                </a>
            </Center>
            <Divider mt={2} mb={2} />
            <CoffeeList roasterId={id} />
        </Box>
    );
}

export default RoasterPage;
