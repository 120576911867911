import {
    Button,
    Center,
    Divider,
    Heading,
    HStack,
    Icon,
    Link,
    Stack,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { BiListCheck } from 'react-icons/bi';
import { FaDiceFour } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import DrinklistEdit from './DrinklistEdit';
import FavoriteCoffeesEdit from './FavoriteCoffeesEdit';
import LikedCoffeesEdit from './LikedCoffeesEdit';
import Login from './Login';
import Register from './Register';
import { UserContext } from './UserContext';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Username from './Username';
import i18n from '../Languages/Translations';

function UserProfileEditPage() {
    const [queryParameters] = useSearchParams();
    const isLoginPage = !!queryParameters.get('login');

    const { user } = useContext(UserContext);

    const pageComponent = isLoginPage ? <Login /> : <Register />;

    return user?.username ? (
        <Center marginY={5}>
            <Stack alignItems={'left'}>
                <VStack alignItems={'center'} gap={2}>
                    <Heading textAlign={'center'} size={'md'}>
                        <Username name={user.username} />
                    </Heading>
                    <Link
                        as={ReactRouterLink}
                        to={`/users/${user.username}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button size="sm" variant="outline">
                            <HStack>
                                <Text fontSize={'xs'}>{i18n.t('publicProfile')}</Text>
                                <ArrowForwardIcon />
                            </HStack>
                        </Button>
                    </Link>
                </VStack>
                <Wrap align="center" pt={4}>
                    <WrapItem>
                        <Icon as={FaDiceFour} w={5} h={5} color={'green.400'} />
                    </WrapItem>
                    <WrapItem>
                        <Heading size="md">{i18n.t('favoriteCoffees')}</Heading>
                    </WrapItem>
                </Wrap>
                <Divider />
                <FavoriteCoffeesEdit />
                <Wrap align="center" pt={6}>
                    <WrapItem>
                        <Icon as={AiFillHeart} w={5} h={5} color={'red.400'} />
                    </WrapItem>
                    <WrapItem>
                        <Heading size="md">{i18n.t('likedCoffees')}</Heading>
                    </WrapItem>
                </Wrap>
                <Divider />
                <LikedCoffeesEdit />
                <Wrap align="center" pt={6}>
                    <WrapItem>
                        <Icon as={BiListCheck} w={6} h={6} color={'blue.400'} />
                    </WrapItem>
                    <WrapItem>
                        <Heading size="md">{i18n.t('drinklist')}</Heading>
                    </WrapItem>
                </Wrap>
                <Divider />
                <DrinklistEdit />
            </Stack>
        </Center>
    ) : (
        pageComponent
    );
}

export default UserProfileEditPage;
