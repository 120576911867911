import {
    Checkbox,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import AdminSubmitButton from './AdminSubmitButton';
import { getCountries, getProcessingMethods, getRoasters, postCoffee } from '../queries';
import i18n from '../Languages/Translations';

function AdminCoffees() {
    const handleFocus = (e: any) => e.target.select();

    const [roasterId, setRoasterId] = useState(0);
    const handleRoasterId = (e: any) => {
        setRoasterId(e.target.value);
    };

    const [name, setName] = useState('');
    const handleName = (e: any) => {
        setName(e.target.value);
    };

    const [siteUrl, setSiteUrl] = useState('');
    const handleSiteUrl = (e: any) => {
        setSiteUrl(e.target.value);
    };

    const [imageUrl, setImageUrl] = useState('');
    const handleImageUrl = (e: any) => {
        setImageUrl(e.target.value);
    };

    const [isDecaf, setIsDecaf] = useState(false);
    const handleIsDecaf = (e: any) => {
        setIsDecaf(e.target.checked);
    };

    const [isSingleOrigin, setIsSingleOrigin] = useState(true);
    const handleIsSingleOrigin = (e: any) => {
        setIsSingleOrigin(e.target.checked);
    };

    const [countryId, setCountryId] = useState('');
    const handleCountryId = (e: any) => {
        setCountryId(e.target.value);
    };

    const [altitudeMin, setAltitudeMin] = useState(1500);
    const handleAltitudeMin = (e: any) => {
        setAltitudeMin(Number(e.target.value));
    };

    const [altitudeMax, setAltitudeMax] = useState(2000);
    const handleAltitudeMax = (e: any) => {
        setAltitudeMax(Number(e.target.value));
    };

    const [processingMethodId, setProcessingMethodId] = useState('');
    const handleProcessingMethodId = (e: any) => {
        setProcessingMethodId(e.target.value);
    };

    const roastersQuery = getRoasters(false);

    const countriesQuery = getCountries(true);

    const processingMethodsQuery = getProcessingMethods();

    return (
        <Stack spacing={3}>
            <Stack spacing={1}>
                <Text>Roaster</Text>
                <Select value={roasterId} placeholder="Select roaster" onChange={handleRoasterId}>
                    {roastersQuery.isFetched
                        ? roastersQuery.roasters.map((roaster) => (
                              <option key={`option_roaster_${roaster.id}`} value={roaster.id}>
                                  {roaster.name}
                              </option>
                          ))
                        : null}
                </Select>
            </Stack>
            <Stack spacing={1}>
                <Text>Name</Text>
                <Input
                    placeholder="Superbeans"
                    value={name}
                    onChange={handleName}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Site URL</Text>
                <Input
                    placeholder="https://www.cafefiltre.ca/superbeans.html"
                    value={siteUrl}
                    onChange={handleSiteUrl}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Image URL</Text>
                <Input
                    placeholder="https://www.cafefiltre.ca/superbeans/picture.jpg"
                    value={imageUrl}
                    onChange={handleImageUrl}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Decaffeinated</Text>
                <Checkbox size="lg" isChecked={isDecaf} onChange={handleIsDecaf}>
                    {isDecaf ? 'Yes' : 'No'}
                </Checkbox>
            </Stack>
            <Stack spacing={1}>
                <Text>Single Origin</Text>
                <Checkbox size="lg" isChecked={isSingleOrigin} onChange={handleIsSingleOrigin}>
                    {isSingleOrigin ? 'Yes' : 'No'}
                </Checkbox>
            </Stack>
            {isSingleOrigin ? (
                <>
                    <Stack spacing={1}>
                        <Text>Country</Text>
                        <Select
                            value={countryId}
                            placeholder="Select country"
                            onChange={handleCountryId}
                        >
                            {countriesQuery.isFetched
                                ? countriesQuery.countries.map((country) => (
                                      <option
                                          key={`option_country_${country.id}`}
                                          value={country.id}
                                      >
                                          {i18n.defaultLocale === 'en'
                                              ? country.nameEn
                                              : country.nameFr}
                                      </option>
                                  ))
                                : null}
                        </Select>
                    </Stack>
                    <Stack spacing={1}>
                        <Text>Processing Method</Text>
                        <Select
                            value={processingMethodId}
                            placeholder="Select processing method"
                            onChange={handleProcessingMethodId}
                        >
                            {processingMethodsQuery.isFetched
                                ? processingMethodsQuery.processingMethods.map((method) => (
                                      <option key={`option_method_${method.id}`} value={method.id}>
                                          {i18n.defaultLocale === 'en'
                                              ? method.nameEn
                                              : method.nameFr}
                                      </option>
                                  ))
                                : null}
                        </Select>
                    </Stack>
                    <Stack spacing={1}>
                        <Text>Altitude</Text>
                        <Stack direction="row">
                            <InputGroup>
                                <InputLeftAddon children="Min" />
                                <Input
                                    maxW={150}
                                    type="number"
                                    value={altitudeMin}
                                    onChange={handleAltitudeMin}
                                    onFocus={handleFocus}
                                />
                            </InputGroup>
                            <Spacer />
                            <InputGroup>
                                <InputLeftAddon children="Max" />
                                <Input
                                    maxW={150}
                                    type="number"
                                    value={altitudeMax}
                                    onChange={handleAltitudeMax}
                                    onFocus={handleFocus}
                                />
                            </InputGroup>
                        </Stack>
                    </Stack>
                </>
            ) : null}
            <AdminSubmitButton
                newObject={{
                    roasterId,
                    name,
                    countryId,
                    siteUrl,
                    imageUrl,
                    altitudeMin,
                    altitudeMax,
                    processingMethodId,
                    isDecaf,
                    isSingleOrigin,
                }}
                postQuery={postCoffee}
            />
        </Stack>
    );
}

export default AdminCoffees;
