import { Icon, Text, VStack } from '@chakra-ui/react';
import { Coffee } from '../types';
import { deleteLike, getIsCoffeeLiked, putLike } from '../queries';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../Users/UserContext';
import { useQueryClient } from 'react-query';
import i18n from '../Languages/Translations';

type Props = {
    coffee: Coffee;
};

function CoffeeLikeButton(props: Props) {
    const queryClient = useQueryClient();

    const { user } = useContext(UserContext);
    const userFound = !!user?.username;

    const coffeeLikedQuery = getIsCoffeeLiked(props.coffee.id);

    const [liked, setLiked] = useState(false);
    const [isHovering, setHover] = useState(false);

    useEffect(() => {
        setLiked(coffeeLikedQuery.isCoffeeLiked);
    }, [coffeeLikedQuery.isCoffeeLiked]);

    if (!coffeeLikedQuery.isFetched) {
        return null;
    }

    const handleLike = async (e: any) => {
        if (liked) {
            await deleteLike(props.coffee.id);
            setLiked(false);
        } else {
            await putLike(props.coffee.id);
            setLiked(true);
        }
        queryClient.invalidateQueries('likes');
    };

    return (
        <VStack
            width={'75px'}
            pointerEvents={userFound ? 'auto' : 'none'}
            opacity={userFound ? '1' : '0.5'}
            cursor="pointer"
            _hover={{ color: 'white' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleLike}
        >
            {liked ? (
                <>
                    <Icon boxSize={6} as={AiFillHeart} color={'red.400'} />
                    <Text fontSize="sm" userSelect="none">
                        {isHovering ? <>{i18n.t('unlike')}</> : <>{i18n.t('liked')}</>}
                    </Text>
                </>
            ) : (
                <>
                    <Icon boxSize={6} as={AiOutlineHeart} />
                    <Text fontSize="sm" userSelect="none">
                        {i18n.t('like')}
                    </Text>
                </>
            )}
        </VStack>
    );
}

export default CoffeeLikeButton;
