import { Input, Select, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import AdminSubmitButton from './AdminSubmitButton';
import { getCities, postRoaster } from '../queries';

function AdminRoasters() {
    const handleFocus = (e: any) => e.target.select();

    const [name, setName] = useState('');
    const handleName = (e: any) => {
        setName(e.target.value);
    };

    const [sortName, setSortName] = useState('');
    const handleSortName = (e: any) => {
        setSortName(e.target.value);
    };

    const [siteUrl, setSiteUrl] = useState('');
    const handleSiteUrl = (e: any) => {
        setSiteUrl(e.target.value);
    };

    const [logoUrl, setLogoUrl] = useState('');
    const handleLogoUrl = (e: any) => {
        setLogoUrl(e.target.value);
    };

    const [logoTheme, setLogoTheme] = useState('NONE');
    const handleLogoTheme = (e: any) => {
        setLogoTheme(e.target.value);
    };

    const [cityId, setCityId] = useState('');
    const handleCityId = (e: any) => {
        setCityId(e.target.value);
    };

    const citiesQuery = getCities();

    return (
        <Stack spacing={3}>
            <Stack spacing={1}>
                <Text>Name</Text>
                <Input
                    placeholder="The Fancy Roaster"
                    value={name}
                    onChange={handleName}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Sort name</Text>
                <Input
                    placeholder="Fancy Roaster"
                    value={sortName}
                    onChange={handleSortName}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Site URL</Text>
                <Input
                    placeholder="https://www.cafefiltre.ca/fancyroaster.html"
                    value={siteUrl}
                    onChange={handleSiteUrl}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Logo URL</Text>
                <Input
                    placeholder="https://www.cafefiltre.ca/fancyroaster/logo.jpg"
                    value={logoUrl}
                    onChange={handleLogoUrl}
                    onFocus={handleFocus}
                />
            </Stack>
            <Stack spacing={1}>
                <Text>Logo Theme</Text>
                <Select
                    value={logoTheme}
                    placeholder="Select logo theme"
                    onChange={handleLogoTheme}
                >
                    <option key={`option_logo_theme_none`} value={'NONE'}>
                        None
                    </option>
                    <option key={`option_logo_theme_light`} value={'LIGHT'}>
                        Light
                    </option>
                    <option key={`option_logo_theme_dark`} value={'DARK'}>
                        Dark
                    </option>
                </Select>
            </Stack>
            <Stack spacing={1}>
                <Text>City</Text>
                <Select value={cityId} placeholder="Select city" onChange={handleCityId}>
                    {citiesQuery.isFetched
                        ? citiesQuery.cities.map((city) => (
                              <option key={`option_city_${city.id}`} value={city.id}>
                                  {city.name}, {city.province}
                              </option>
                          ))
                        : null}
                </Select>
            </Stack>
            <AdminSubmitButton
                newObject={{
                    name,
                    sortName,
                    siteUrl,
                    logoUrl,
                    logoTheme,
                    cityId,
                }}
                postQuery={postRoaster}
            />
        </Stack>
    );
}

export default AdminRoasters;
