import {
    HStack,
    Icon,
    Link,
    Modal,
    ModalOverlay,
    Select,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/fr';
import { FaInstagram, FaPaperPlane } from 'react-icons/fa';
import { Link as ReactRouterLink } from 'react-router-dom';
import About from './About';
import i18n from './Languages/Translations';
import Username from './Users/Username';

function AppFooter() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleLang = (e: any) => {
        const lang = e.target.value;
        i18n.defaultLocale = lang;
        i18n.locale = lang;
        localStorage.setItem('lang', lang);
        moment.locale(lang);
    };

    return (
        <VStack alignItems={'center'} py={6} textAlign={'center'} color={'gray.300'}>
            <HStack>
                <Text fontSize="md" userSelect="none">
                    © {new Date().getFullYear()} Café filtre
                </Text>
                <Text fontSize="md" userSelect="none">
                    •
                </Text>
                <Text size="md" cursor="pointer" onClick={onOpen}>
                    FAQ
                </Text>
                <Modal size="xl" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <About />
                </Modal>
                <Select
                    value={i18n.defaultLocale}
                    onChange={handleLang}
                    pl={4}
                    width={'fit-content'}
                    size="xs"
                    bgColor="#323232"
                >
                    <option value="fr">FR</option>
                    <option value="en">EN</option>
                </Select>
            </HStack>
            <HStack spacing="16px">
                <Text fontSize="md" userSelect="none">
                    {i18n.t('madeInMTL')}
                    <Link
                        ml={1}
                        as={ReactRouterLink}
                        to={`/users/Pinpoint`}
                        _hover={{ color: 'white' }}
                    >
                        <Username name={'Pinpoint'} />
                    </Link>
                </Text>
                <Link href="mailto:contact@cafefiltre.ca" isExternal>
                    <Icon as={FaPaperPlane} boxSize={6} pt={1} />
                </Link>
                <Link href="https://www.instagram.com/cafefiltreqc/" isExternal>
                    <Icon as={FaInstagram} boxSize={6} pt={1} />
                </Link>
            </HStack>
        </VStack>
    );
}

export default AppFooter;
