import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Flex, Link, Select, Text, useMediaQuery } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = {
    coffeeCount: number;
    currentPage: number;
    nbPerPage: number;
    setNbPerPage: any;
};

function CoffeePagination(props: Props) {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const numberOfPages = Math.floor(props.coffeeCount / props.nbPerPage) + 1;
    const pages = Array.from(Array(numberOfPages).keys());

    const handleNbPerPage = (e: any) => {
        props.setNbPerPage(parseInt(e.target.value));
    };

    return (
        <Flex mt={2} align={'center'} justify={'center'} gap={4}>
            {props.currentPage > 1 ? (
                <Link as={ReactRouterLink} to={`/coffees?page=${props.currentPage - 1}`}>
                    <Button size="sm">
                        <ArrowBackIcon />
                    </Button>
                </Link>
            ) : (
                <Button isDisabled size="sm">
                    <ArrowBackIcon />
                </Button>
            )}
            {!isMobile &&
                pages.map((page) =>
                    page === props.currentPage - 1 ? (
                        <Text key={`text_${page}`} fontSize={'md'} color={'gray'} userSelect="none">
                            {page + 1}
                        </Text>
                    ) : (
                        <Link key={`text_${page}`}  as={ReactRouterLink} to={`/coffees?page=${page + 1}`}>
                            <Text fontSize={'md'}>{page + 1}</Text>
                        </Link>
                    )
                )}
            {props.currentPage < numberOfPages ? (
                <Link as={ReactRouterLink} to={`/coffees?page=${props.currentPage + 1}`}>
                    <Button size="sm">
                        <ArrowForwardIcon />
                    </Button>
                </Link>
            ) : (
                <Button isDisabled size="sm">
                    <ArrowForwardIcon />
                </Button>
            )}
            <Select ml={4} size='sm' width={16} value={props.nbPerPage} onChange={handleNbPerPage}>
                <option value="18">18</option>
                <option value="10000">All</option>
            </Select>
        </Flex>
    );
}

export default CoffeePagination;
