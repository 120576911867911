import { Heading, Stack, Text } from '@chakra-ui/react';
import i18n from './Languages/Translations';

function NotFound() {
    return (
        <Stack marginY={20} spacing={8} alignItems={'center'}>
            <Heading>404</Heading>
            <Text>{i18n.t('lookingForTea')}</Text>
        </Stack>
    );
}

export default NotFound;
