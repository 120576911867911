import {
    Button,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text,
} from '@chakra-ui/react';
import { getCountries, getProcessingMethods } from '../queries';
import { TbFilter } from 'react-icons/tb';
import i18n from '../Languages/Translations';

type Props = {
    isSingleOrigin: boolean;
    setIsSingleOrigin: any;
    isDecaffeinated: boolean;
    setIsDecaffeinated: any;
    filterCountries: string[];
    setFilterCountries: any;
    filterProcessingMethods: string[];
    setFilterProcessingMethods: any;
};

function CoffeeListFilterButton(props: Props) {
    const countriesQuery = getCountries();

    const processingMethodsQuery = getProcessingMethods();

    return (
        <Menu closeOnSelect={false}>
            <MenuButton size="xs" as={Button} color="white">
                <Flex align={'center'}>
                    <Icon as={TbFilter} boxSize={4} mr={2} />
                    <Text fontSize="sm">{i18n.t('filter')}</Text>
                </Flex>
            </MenuButton>
            <MenuList as="ul" minW="240px" maxHeight={'600px'} sx={{ overflow: 'scroll' }}>
                <MenuOptionGroup
                    type="checkbox"
                    value={props.isSingleOrigin ? ['single-origin'] : []}
                    onChange={(values) => props.setIsSingleOrigin(values.length === 1)}
                >
                    <MenuItemOption as="li" value="single-origin">
                        {i18n.t('singleOrigin')}
                    </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                    type="checkbox"
                    value={props.isDecaffeinated ? ['decaffeinated'] : []}
                    onChange={(values) => props.setIsDecaffeinated(values.length === 1)}
                >
                    <MenuItemOption as="li" value="decaffeinated">
                        {i18n.t('decaffeinated')}
                    </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                    title={i18n.t('country')}
                    type="checkbox"
                    value={props.filterCountries}
                    onChange={(values) => props.setFilterCountries(values as string[])}
                >
                    {countriesQuery.isFetched &&
                        countriesQuery.countries.map((country) => (
                            <MenuItemOption
                                key={'menuitem' + country.id}
                                as="li"
                                value={country.id}
                            >
                                {i18n.defaultLocale === 'en' ? country.nameEn : country.nameFr}
                            </MenuItemOption>
                        ))}
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                    title={i18n.t('processingMethod')}
                    type="checkbox"
                    value={props.filterProcessingMethods}
                    onChange={(values) => props.setFilterProcessingMethods(values as string[])}
                >
                    {processingMethodsQuery.isFetched &&
                        processingMethodsQuery.processingMethods
                            .sort((pm1, pm2) =>
                                i18n.defaultLocale === 'en'
                                    ? pm1.nameEn < pm2.nameEn
                                        ? -1
                                        : 1
                                    : pm1.nameFr < pm2.nameFr
                                    ? -1
                                    : 1
                            )
                            .map((pm) => (
                                <MenuItemOption key={'menuitem' + pm.id} as="li" value={pm.id}>
                                    {i18n.defaultLocale === 'en' ? pm.nameEn : pm.nameFr}
                                </MenuItemOption>
                            ))}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    );
}

export default CoffeeListFilterButton;
