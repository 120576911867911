import { Center, Divider, Heading, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import i18n from '../Languages/Translations';
import { getCoffeeLogs } from '../queries';
import { UserContext } from '../Users/UserContext';
import CoffeeLogTable from './CoffeeLogTable';

function UserDiary() {
    const { user } = useContext(UserContext);
    const userFound = !!user?.username;

    const coffeeLogsQuery = getCoffeeLogs();

    if (!userFound || !coffeeLogsQuery.isFetched) {
        return null;
    }

    return (
        <Center marginY={5}>
            <VStack alignItems={'center'} gap={2}>
                <Heading textAlign={'center'} size={'md'}>
                    {i18n.t('diary')}
                </Heading>
                <Divider />
                <CoffeeLogTable coffeeLogs={coffeeLogsQuery.coffeeLogs} showAllLogs />
            </VStack>
        </Center>
    );
}

export default UserDiary;
