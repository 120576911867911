import {
    Box,
    Button,
    Card,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { cardStyles } from '../styles/Card';
import { Coffee, COFFEES_IMG_URL } from '../types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { MinusIcon } from '@chakra-ui/icons';
import { useQueryClient } from 'react-query';
import { useState } from 'react';
import i18n from '../Languages/Translations';

type Props = {
    coffee: Coffee;
    size: 'sm' | 'md' | 'lg';
    deleteFn?: () => Promise<any>;
};

function CoffeeCardLight(props: Props) {
    const coffee = props.coffee;
    const queryClient = useQueryClient();

    const [isButtonHovering, setButtonHover] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async (e: any) => {
        setIsDeleting(true);
        props.deleteFn && (await props.deleteFn());
        setIsDeleting(false);
        queryClient.invalidateQueries('likes');
        queryClient.invalidateQueries('drinklist');
        queryClient.invalidateQueries('favorites');
    };

    const cardWidth = props.size === 'sm' ? '80px' : props.size === 'md' ? '100px' : '150px';
    const cardHeight = props.size === 'sm' ? '80px' : props.size === 'md' ? '130px' : '185px';
    const imageHeight = props.size === 'sm' ? '60px' : props.size === 'md' ? '85px' : '130px';

    return (
        <Box textAlign={'center'}>
            <Link
                as={ReactRouterLink}
                to={`/coffees/${coffee.id}`}
                style={{ textDecoration: 'none' }}
            >
                <Tooltip
                    hasArrow
                    label={
                        <Stack textAlign={'center'} spacing={0}>
                            <Text fontWeight="2" fontStyle="italic">
                                {coffee.roasterName}
                            </Text>
                            <Text>{coffee.name}</Text>
                        </Stack>
                    }
                    placement="top"
                >
                    <Card
                        width={cardWidth}
                        height={cardHeight}
                        {...cardStyles()}
                        p="10px"
                        mb={props.deleteFn ? '-15px' : ''}
                        justifyContent={'start'}
                    >
                        <VStack>
                            <Image
                                src={`${COFFEES_IMG_URL}/${coffee.id}_small.png`}
                                fallbackSrc={coffee.imageUrl}
                                fallbackStrategy="onError"
                                alt={'Logo of ' + coffee.name}
                                borderRadius="lg"
                                objectFit="cover"
                                width={`100%`}
                                height={imageHeight}
                                opacity={isButtonHovering ? '0.3' : '1'}
                            />
                            {props.size !== 'sm' && (
                                <Text textAlign="center" fontSize="12" noOfLines={1}>
                                    {coffee.roasterName}
                                </Text>
                            )}
                        </VStack>
                    </Card>
                </Tooltip>
            </Link>
            {props.deleteFn && (
                <Popover>
                    <PopoverTrigger>
                        <Button
                            size="xs"
                            height={6}
                            width={6}
                            borderRadius={50}
                            bgColor={'red.700'}
                            variant="outline"
                            _hover={{ bgColor: 'red.500' }}
                            onMouseEnter={() => setButtonHover(true)}
                            onMouseLeave={() => setButtonHover(false)}
                        >
                            <MinusIcon />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent w={'fit-content'}>
                        <PopoverArrow bgColor="#3b3b3b" />
                        <Button
                            size="xs"
                            variant="ghost"
                            onClick={handleDelete}
                            isLoading={isDeleting}
                            _hover={{
                                bgColor: 'orange.500',
                            }}
                        >
                            {i18n.t('remove')}
                        </Button>
                    </PopoverContent>
                </Popover>
            )}
        </Box>
    );
}

export default CoffeeCardLight;
