import { I18n } from 'i18n-js';
import moment from 'moment';
import 'moment/locale/fr';

const i18n = new I18n({
    fr: {
        welcomeMsg: 'Bienvenue sur Café filtre. Découvrez les délicieux cafés du Québec...',
        roasters: 'Torréfacteurs',
        zeroCoffee: 'Café',
        coffee: 'Café',
        coffees: 'Cafés',
        members: 'Membres',
        popularBeans: 'Grains populaires',
        latestDrops: 'Ajouts récents',
        searchPlaceholder: 'Chercher un café',
        searchForPick: 'Choisir un café',
        searchNoResults: 'Pas de résultat',
        profile: 'Profil',
        diary: 'Journal',
        settings: 'Options',
        signIn: 'Connexion',
        signOut: 'Déconnexion',
        madeInMTL: 'Fait à Montréal par',
        decaffeinated: 'Décaféiné',
        blend: 'Mélange',
        remove: 'Enlever',
        drinklist: 'À déguster',
        likes: 'Aime',
        like: 'Aimer',
        liked: 'Aime',
        unlike: 'Enlever',
        coffeeLogged: 'Café enregistré',
        roastDate: 'Date de torréfaction',
        tastingDate: 'Date de dégustation',
        filter: 'Filtre',
        singleOrigin: 'Origine unique',
        country: 'Pays',
        processingMethod: 'Procédé',
        sort: 'Tri',
        mostPopular: 'Popularité',
        roaster: 'Torréfacteur',
        name: 'Nom',
        altitude: 'Altitude',
        recentlyAdded: 'Ajouts récents',
        log: 'Enregistrer',
        logged: 'Enregistré',
        edit: 'Modifier',
        editLogs: 'Éditer les enregistrements',
        newLog: 'Nouvel enregistrement',
        selectDate: 'Sélectionner une date',
        notes: 'Notes privées',
        website: 'Site',
        likedCoffees: 'Cafés aimés',
        favoriteCoffees: 'Cafés préférés',
        notesEdited: 'Notes modifiées',
        dateEdited: 'Date modifiée',
        save: 'Enregistrer',
        cancel: 'Annuler',
        delete: 'Supprimer',
        noCoffeesInDrinklist: 'Aucun café à déguster',
        noLikedCoffees: 'Aucun café aimé',
        accountVerified: 'Votre compte a bien été vérifié',
        alreadyHaveAccount: 'Déjà membre ?',
        usernameOrMail: 'Identifiant ou courriel',
        username: 'Identifiant',
        password: 'Mot de passe',
        usernamePwIncorrect: 'Identifiant ou mot de passe incorrect.',
        createAccountInstead: 'Ou créer un compte',
        register: 'Créer un compte',
        emailAddress: 'Courriel',
        emailNoSharing: 'Votre courriel ne sera pas rendu public',
        createAccount: 'Créer',
        alreadyRegistered: 'Déjà membre ? Se connecter',
        verifMailSent: 'Un courriel de vérification a été envoyé. Il devrait arriver sous peu.',
        publicProfile: 'Profil public',
        crew: 'Équipe',
        userNotFound: 'Membre inconnu',
        lookingForTea: 'À la recherche de thé ? 😿',
        lastUpdatedOn: 'Mis à jour',
    },
    en: {
        welcomeMsg: "Welcome to Café filtre. Here's some damn good coffees from Québec...",
        roasters: 'Roasters',
        zeroCoffee: 'Coffees',
        coffee: 'Coffee',
        coffees: 'Coffees',
        members: 'Members',
        popularBeans: 'Popular beans',
        latestDrops: 'Latest drops',
        searchPlaceholder: 'Search for roasters and coffees',
        searchForPick: 'Pick a coffee',
        searchNoResults: 'No results',
        profile: 'Profile',
        diary: 'Diary',
        settings: 'Settings',
        signIn: 'Sign in',
        signOut: 'Sign out',
        madeInMTL: 'Made in Montreal by',
        decaffeinated: 'Decaffeinated',
        blend: 'Blend',
        remove: 'Remove',
        drinklist: 'Drinklist',
        likes: 'Likes',
        like: 'Like',
        liked: 'Liked',
        unlike: 'Unlike',
        coffeeLogged: 'Coffee logged',
        roastDate: 'Roast date',
        tastingDate: 'Tasting date',
        filter: 'Filter',
        singleOrigin: 'Single origin',
        country: 'Country',
        processingMethod: 'Processing method',
        sort: 'Sort',
        mostPopular: 'Most popular',
        roaster: 'Roaster',
        name: 'Name',
        altitude: 'Altitude',
        recentlyAdded: 'Recently added',
        log: 'Log',
        logged: 'Logged',
        edit: 'Edit',
        editLogs: 'Edit logs',
        newLog: 'New log',
        selectDate: 'Select date',
        notes: 'Private notes',
        website: 'website',
        likedCoffees: 'Liked coffees',
        favoriteCoffees: 'Favorite coffees',
        notesEdited: 'Notes edited',
        dateEdited: 'Date edited',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        noCoffeesInDrinklist: 'No coffees in drinklist',
        noLikedCoffees: 'No liked coffees',
        accountVerified: 'Your account has been verified',
        alreadyHaveAccount: 'Already have an account?',
        usernameOrMail: 'Username or Email',
        username: 'Username',
        password: 'Password',
        usernamePwIncorrect: 'Username or password is incorrect.',
        createAccountInstead: 'Create an account instead',
        register: 'Register',
        emailAddress: 'Email address',
        emailNoSharing: "We'll never share your email.",
        createAccount: 'Create account',
        alreadyRegistered: 'Already registered? Sign in',
        verifMailSent:
            'A verification email has been sent to the address you provided. It will arrive in a few minutes.',
        publicProfile: 'Public profile',
        crew: 'Crew',
        userNotFound: 'Member not found',
        lookingForTea: 'Looking for some tea? 😿',
        lastUpdatedOn: 'Last updated',
    },
});

i18n.defaultLocale = localStorage.getItem('lang') || 'fr';
i18n.locale = i18n.defaultLocale;

moment.locale(i18n.defaultLocale);

export default i18n;
