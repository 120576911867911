import {
    Box,
    chakra,
    Flex,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    useMediaQuery,
} from '@chakra-ui/react';
import SearchBar from './Search/SearchBar';
import { Link as ReactRouterLink } from 'react-router-dom';
import UserHeader from './Users/UserHeader';
import { IMG_URL } from './types';
import { HamburgerIcon } from '@chakra-ui/icons';
import i18n from './Languages/Translations';

function AppHeader() {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    return (
        <chakra.header
            pos="sticky"
            top="0"
            left="0"
            right="0"
            zIndex="3"
            width="full"
            background={'#151515'}
        >
            <Box height="4.5rem" mx="auto" maxW="1000px">
                <Flex w="100%" h="100%" px="6" align="center" paddingX={0}>
                    <Flex whiteSpace={'nowrap'} paddingTop={'2px'} marginLeft={4}>
                        <Link as={ReactRouterLink} to="/" style={{ textDecoration: 'none' }}>
                            <Image
                                src={`${IMG_URL}/logo_text.png`}
                                alt={'Logo of Café filtre'}
                                height={'40px'}
                            />
                        </Link>
                    </Flex>
                    {isMobile ? (
                        <>
                            <Spacer />
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    aria-label="Options"
                                    icon={<HamburgerIcon />}
                                    variant="outline"
                                    size='sm'
                                />
                                <MenuList>
                                    <Link
                                        as={ReactRouterLink}
                                        to="/roasters"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <MenuItem>{i18n.t('roasters')}</MenuItem>
                                    </Link>
                                    <Link
                                        as={ReactRouterLink}
                                        to="/coffees"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <MenuItem>{i18n.t('coffees')}</MenuItem>
                                    </Link>
                                    <Link
                                        as={ReactRouterLink}
                                        to="/users"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <MenuItem>{i18n.t('members')}</MenuItem>
                                    </Link>
                                </MenuList>
                            </Menu>
                        </>
                    ) : (
                        <Flex gap={8} marginX={8}>
                            <Link
                                as={ReactRouterLink}
                                to="/roasters"
                                style={{ textDecoration: 'none' }}
                            >
                                <Text
                                    fontSize={'14px'}
                                    color={'#9ab'}
                                    fontWeight={'bold'}
                                    letterSpacing={'.05em'}
                                    textTransform={'uppercase'}
                                    _hover={{ color: 'white' }}
                                >
                                    {i18n.t('roasters')}
                                </Text>
                            </Link>
                            <Link
                                as={ReactRouterLink}
                                to="/coffees"
                                style={{ textDecoration: 'none' }}
                            >
                                <Text
                                    fontSize={'14px'}
                                    color={'#9ab'}
                                    fontWeight={'bold'}
                                    letterSpacing={'.05em'}
                                    textTransform={'uppercase'}
                                    _hover={{ color: 'white' }}
                                >
                                    {i18n.t('coffees')}
                                </Text>
                            </Link>
                            <Link
                                as={ReactRouterLink}
                                to="/users"
                                style={{ textDecoration: 'none' }}
                            >
                                <Text
                                    fontSize={'14px'}
                                    color={'#9ab'}
                                    fontWeight={'bold'}
                                    letterSpacing={'.05em'}
                                    textTransform={'uppercase'}
                                    _hover={{ color: 'white' }}
                                >
                                    {i18n.t('members')}
                                </Text>
                            </Link>
                        </Flex>
                    )}
                    {!isMobile && (
                        <Flex flex={1} alignSelf="start">
                            <SearchBar />
                        </Flex>
                    )}
                    <Flex marginX={4} justify="flex-end">
                        <UserHeader />
                    </Flex>
                </Flex>
            </Box>
        </chakra.header>
    );
}

export default AppHeader;
