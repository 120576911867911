import { createContext } from 'react';
import { UserPrivate } from '../types';

export interface UserContextType {
    user: UserPrivate | undefined;
    setUser: (u: UserPrivate|undefined) => void;
}

export const UserContext = createContext<UserContextType>({
    user: undefined,
    setUser: () => {},
});
