import {
    Button,
    Center,
    Heading,
    Input,
    Link,
    Text,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import { postRegister } from '../queries';
import { Link as ReactRouterLink } from 'react-router-dom';
import i18n from '../Languages/Translations';

function Register() {
    const [mail, setMail] = useState('');
    const handleMail = (e: any) => {
        setMail(e.target.value);
    };

    const [username, setUsername] = useState('');
    const handleUsername = (e: any) => {
        setUsername(e.target.value);
    };

    const [password, setPassword] = useState('');
    const handlePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e: any) => {
        const response = await postRegister(mail, username, password);
        if (!response.ok) {
            const errMsg = await response.text();
            setErrorMessage(errMsg);
        } else {
            window.location.href = '/settings?mailSent=true';
        }
    };

    return (
        <Center marginY={5}>
            <Stack alignItems={'center'} spacing={8}>
                <Heading size={'md'}>{i18n.t('register')}</Heading>
                <FormControl w={350} isRequired isInvalid={errorMessage !== ''}>
                    <FormLabel mt={4}>{i18n.t('emailAddress')}</FormLabel>
                    <Input type={'email'} value={mail} onChange={handleMail} required />
                    <FormHelperText>{i18n.t('emailNoSharing')}</FormHelperText>
                    <FormLabel mt={4}>{i18n.t('username')}</FormLabel>
                    <Input type={'username'} value={username} onChange={handleUsername} required />
                    <FormLabel mt={4}>{i18n.t('password')}</FormLabel>
                    <Input type={'password'} value={password} onChange={handlePassword} required />
                    <Center>
                        <Button
                            mt={4}
                            loadingText="Loading"
                            spinnerPlacement="start"
                            onClick={handleSubmit}
                        >
                            {i18n.t('createAccount')}
                        </Button>
                    </Center>
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                </FormControl>
                <Text>
                    <Link as={ReactRouterLink} to={'/settings?login=true'}>
                        {i18n.t('alreadyRegistered')}
                    </Link>
                </Text>
            </Stack>
        </Center>
    );
}

export default Register;
