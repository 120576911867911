import {
    Center,
    Icon,
    Link,
    Table,
    TableContainer,
    Text,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Wrap,
    WrapItem,
    Heading,
} from '@chakra-ui/react';
import { AiFillHeart } from 'react-icons/ai';
import LogoSpinner from '../LogoSpinner';
import { getUsers } from '../queries';
import { Link as ReactRouterLink } from 'react-router-dom';
import Username from './Username';
import { BiListCheck } from 'react-icons/bi';
import { MdCoffee } from 'react-icons/md';
import i18n from '../Languages/Translations';

function UsersPage() {
    const usersQuery = getUsers();

    if (usersQuery.isFetching) {
        return (
            <Center>
                <LogoSpinner />
            </Center>
        );
    }
    return (
        <Center marginY={5}>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>{i18n.t('username')}</Th>
                            <Th>{i18n.t('diary')}</Th>
                            <Th>{i18n.t('likes')}</Th>
                            <Th>{i18n.t('drinklist')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {usersQuery.users.map((user) => (
                            <Tr key={`tr_${user.username}`}>
                                <Td>
                                    <Link
                                        as={ReactRouterLink}
                                        to={`/users/${user.username}`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Heading size={'sm'}>
                                            <Username name={user.username} />
                                        </Heading>
                                    </Link>
                                </Td>
                                <Td>
                                    <Wrap align="center">
                                        <WrapItem>
                                            <Icon as={MdCoffee} color={'teal.500'} />
                                        </WrapItem>
                                        <WrapItem>
                                            <Text>{user.coffeeLogCount}</Text>
                                        </WrapItem>
                                    </Wrap>
                                </Td>
                                <Td>
                                    <Wrap align="center">
                                        <WrapItem>
                                            <Icon as={AiFillHeart} color={'red.400'} />
                                        </WrapItem>
                                        <WrapItem>
                                            <Text>{user.likeCount}</Text>
                                        </WrapItem>
                                    </Wrap>
                                </Td>
                                <Td>
                                    <Wrap align="center">
                                        <WrapItem>
                                            <Icon as={BiListCheck} color={'blue.400'}  />
                                        </WrapItem>
                                        <WrapItem>
                                            <Text>{user.drinklistCount}</Text>
                                        </WrapItem>
                                    </Wrap>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Center>
    );
}

export default UsersPage;
