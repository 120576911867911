import { Image } from '@chakra-ui/react';
import { Roaster, ROASTERS_IMG_URL } from '../types';

type Props = {
    roaster: Roaster;
    maxHeight: string;
    width: string;
};

function RoasterLogo(props: Props) {
    const roaster = props.roaster;

    const keepLogoColors =
        roaster.logoTheme === 'NONE' || roaster.logoTheme === 'DARK';

    return (
        <Image
            src={`${ROASTERS_IMG_URL}/${roaster.id}.png`}
            fallbackSrc={roaster.logoUrl}
            fallbackStrategy='onError'
            alt={'Logo of ' + roaster.name}
            maxHeight={props.maxHeight}
            width={props.width}
            objectFit="contain"
            bgColor={keepLogoColors ? 'transparent' : 'gray.300'}
            p={keepLogoColors ? '0' : '1'}
            borderRadius={keepLogoColors ? '0' : '10px'}
        />
    );
}

export default RoasterLogo;
