import { Center, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import AdminCities from './AdminCities';
import AdminCoffees from './AdminCoffees';
import AdminProcessingMethods from './AdminProcessingMethods';
import AdminRoasters from './AdminRoasters';

function AdminPage() {
    return (
        <Center marginY={5}>
            <Tabs isFitted variant="enclosed">
                <TabList mb="1em">
                    <Tab>Roasters</Tab>
                    <Tab>Coffees</Tab>
                    <Tab>Cities</Tab>
                    <Tab>Processing Methods</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AdminRoasters />
                    </TabPanel>
                    <TabPanel>
                        <AdminCoffees />
                    </TabPanel>
                    <TabPanel>
                        <AdminCities />
                    </TabPanel>
                    <TabPanel>
                        <AdminProcessingMethods />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Center>
    );
}

export default AdminPage;
