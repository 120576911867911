import { Box, Center, Divider, Text } from '@chakra-ui/react';
import RoasterList from './RoasterList';
import { getRoasters } from '../queries';
import LogoSpinner from '../LogoSpinner';
import i18n from '../Languages/Translations';

function RoastersPage() {
    const roastersQuery = getRoasters();

    if (roastersQuery.isFetching) {
        return (
            <Center>
                <LogoSpinner />
            </Center>
        );
    }

    return (
        <Box textAlign={'right'}>
            <Text mt={2} fontSize={'sm'} fontStyle="italic">
                {roastersQuery.roasters.length} {i18n.t('roasters').toLowerCase()}
            </Text>
            <Divider my={2} />
            <RoasterList roasters={roastersQuery.roasters} />
        </Box>
    );
}

export default RoastersPage;
