import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import { menuTheme } from './styles/Menu';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const theme = extendTheme({
    config: { initialColorMode: 'dark' },
    components: {
        Modal: {
            baseStyle: {
                dialog: {
                    bg: '#3b3b3b',
                    borderRadius: 'lg',
                },
            },
        },
        Menu: menuTheme,
        Popover: {
            baseStyle: {
                content: {
                    textColor: 'white',
                    bg: '#3b3b3b',
                },
            },
        },
    },
    styles: {
        global: {
            a: {
                _hover: {
                    textDecoration: 'none',
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </ChakraProvider>
    </React.StrictMode>
);
