import {
    Center,
    Divider,
    Heading,
    Icon,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { AiFillHeart } from 'react-icons/ai';
import { getLatestCoffees, getPopularCoffees } from './queries';
import { CiCoffeeBean } from 'react-icons/ci';
import CoffeeCardLight from './Coffees/CoffeeCardLight';
import i18n from './Languages/Translations';
import moment from 'moment';

function HomePage() {
    const popularCoffeesQuery = getPopularCoffees();
    const latestCoffeesQuery = getLatestCoffees();

    if (!popularCoffeesQuery.isFetched || !latestCoffeesQuery.isFetched) {
        return null;
    }

    const updatedDate = moment(latestCoffeesQuery.coffees[0].createdAt).fromNow();

    return (
        <Center marginY={5}>
            <Stack alignItems={'left'}>
                <VStack alignItems={'center'} gap={2}>
                    <Heading textAlign={'center'} fontSize={'2xl'} fontWeight={'light'}>
                        {i18n.t('welcomeMsg')}
                    </Heading>
                    <Wrap align="center" pt={4}>
                        <WrapItem>
                            <Icon as={AiFillHeart} w={5} h={5} color={'red.400'} />
                        </WrapItem>
                        <WrapItem>
                            <Heading size="md">{i18n.t('popularBeans')}</Heading>
                        </WrapItem>
                    </Wrap>
                    <Divider />
                    <Center>
                        <SimpleGrid columns={[3, null, 6]} spacing="10px">
                            {popularCoffeesQuery.coffees.map((coffee) => (
                                <CoffeeCardLight
                                    key={'coffee_' + coffee.id}
                                    coffee={coffee}
                                    size="md"
                                />
                            ))}
                        </SimpleGrid>
                    </Center>
                    <Wrap align="center" pt={4}>
                        <WrapItem>
                            <Icon as={CiCoffeeBean} w={5} h={5} color={'green.400'} />
                        </WrapItem>
                        <WrapItem>
                            <Heading size="md">{i18n.t('latestDrops')}</Heading>
                        </WrapItem>
                        <WrapItem>
                            <Text size="sm">
                                ({i18n.t('lastUpdatedOn')} {updatedDate})
                            </Text>
                        </WrapItem>
                    </Wrap>
                    <Divider />
                    <Center>
                        <SimpleGrid columns={[3, null, 6]} spacing="10px">
                            {latestCoffeesQuery.coffees.map((coffee) => (
                                <CoffeeCardLight
                                    key={'coffee_' + coffee.id}
                                    coffee={coffee}
                                    size="md"
                                />
                            ))}
                        </SimpleGrid>
                    </Center>
                </VStack>
            </Stack>
        </Center>
    );
}

export default HomePage;
