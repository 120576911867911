import { SearchIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AiOutlineFire } from 'react-icons/ai';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Icon,
    InputRightElement,
    Link,
    Spinner,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { searchCoffee } from '../queries';
import { Coffee, SearchResult } from '../types';
import { debounce } from 'lodash';
import { CiCoffeeBean } from 'react-icons/ci';
import i18n from '../Languages/Translations';

type Props = {
    handleFn?: (coffeeId: number) => Promise<any>;
    focusRef?: any;
};

function SearchBar(props: Props) {
    const [input, setInput] = useState('');
    const [results, setResults] = useState<SearchResult[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const handleDebounceFn = async (query: string) => {
        setIsLoading(true);
        const response = await searchCoffee(query, !!props.handleFn);
        if (response.ok) {
            const searchResults: SearchResult[] = JSON.parse(await response.text());
            setIsLoading(false);
            setResults(searchResults);
        }
    };

    const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);

    const handleInputChange = async (e: any) => {
        setInput(e.target.value);
        debounceFn(e.target.value);
    };

    const handleInputKeyDown = async (e: any) => {
        if (e.key === 'Escape') {
            setInput('');
            e.target.blur();
        }
    };

    return (
        <Box w="100%" mt={props.handleFn ? '0' : '16px'} onBlur={() => setResults(undefined)}>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color={'whiteAlpha.400'} />}
                />
                <Input
                    ref={props.focusRef}
                    bgColor={'whiteAlpha.200'}
                    placeholder={
                        props.handleFn ? i18n.t('searchForPick') : i18n.t('searchPlaceholder')
                    }
                    value={input}
                    onKeyDown={handleInputKeyDown}
                    onChange={handleInputChange}
                    onFocus={handleInputChange}
                />
                <InputRightElement height="100%">
                    {isLoading ? <Spinner color="teal.500" size="sm" /> : null}
                </InputRightElement>
            </InputGroup>
            {results && (
                <Box
                    bgColor="#272727"
                    position="relative"
                    maxHeight="225px"
                    overflowY="auto"
                    borderRadius="0px 0px 10px 10px"
                    boxShadow="0 2px 4px 0 rgb(0 0 0 / 50%), 0 2px 10px 0 rgb(0 0 0 / 50%);"
                    marginTop="2px"
                    width="100%"
                >
                    {results.length > 0 ? (
                        results.map((result) => (
                            <Link
                                key={'result_' + result.type + result.item.id}
                                as={ReactRouterLink}
                                to={`/${result.type === 'ROASTER' ? 'roasters' : 'coffees'}/${
                                    result.item.id
                                }`}
                                onMouseDown={(e) => {
                                    props.handleFn
                                        ? props.handleFn(result.item.id)
                                        : e.preventDefault();
                                }}
                                style={{ textDecoration: 'none' }}
                            >
                                <Box
                                    borderBottom="1px solid rgba(34,36,38,.1)"
                                    cursor="pointer"
                                    _hover={{
                                        bgColor: 'teal.700',
                                    }}
                                >
                                    <Flex alignItems="center">
                                        <Flex p="0.8em" margin="0" color="white" align={'center'}>
                                            {result.type === 'ROASTER' ? (
                                                <>
                                                    <Icon as={AiOutlineFire} marginRight={'2'} />
                                                    <Text>{result.item.name}</Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Icon as={CiCoffeeBean} marginRight={'2'} />
                                                    <Text>
                                                        {result.item.name} (
                                                        {(result.item as Coffee).roasterName})
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Link>
                        ))
                    ) : (
                        <Box borderBottom="1px solid rgba(34,36,38,.1)">
                            <Flex alignItems="center">
                                <Box p="0.8em" margin="0" color="white">
                                    <Text fontStyle={'italic'}>{i18n.t('searchNoResults')}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default SearchBar;
